import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { getShipmentContainersByShipmentId, updateBatchShipmentContainers } from "../../api/imports";
import { useSnack } from "../../services/SnakbarProvider";

interface Container {
  code: string;
  portArrivalDate: string;
  plantArrivalDate: string;
  returnDate: string;
  delayCharges: number;
  shipmentId: string;
}

interface ManageShipmentContainersProps {
  props: {
    id: string;
    onSubmit: any;
  };
}

export const ManageShipmentContainers: React.FC<
  ManageShipmentContainersProps
> = ({ props }) => {
  const { id, onSubmit } = props;
  const [containers, setContainers] = useState<Container[]>([]);
  const [newContainerCode, setNewContainerCode] = useState<string>("");
  const { openSnack } = useSnack();

  
  
  const fetchContainers = async () => {
    const response = await getShipmentContainersByShipmentId(id);
    setContainers(response.data);
  };

  useEffect(() => {
    fetchContainers();
  }, []);

  const handleContainerUpdate = (
    containerCode: string,
    field: keyof Container,
    value: string | number
  ) => {
    setContainers((prev) =>
      prev.map((container) =>
        container.code === containerCode
          ? { ...container, [field]: value }
          : container
      )
    );
  };

  const handleSave = async () => {
    try {
      const response = await updateBatchShipmentContainers(containers);
      openSnack("Contenedores actualizados correctamente", "success");
      onSubmit();
    } catch (error) {
      openSnack("Error al actualizar contenedores", "error");
    }
  };

  const handleAddContainer = () => {
    if (!newContainerCode.trim()) return;
    
    const newContainer: Container = {
      code: newContainerCode.trim(),
      portArrivalDate: "",
      plantArrivalDate: "",
      returnDate: "",
      delayCharges: 0,
      shipmentId: id,
    };

    setContainers(prev => [...prev, newContainer]);
    setNewContainerCode("");
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
        <TextField
          label="Código de contenedor"
          value={newContainerCode}
          onChange={(e) => setNewContainerCode(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <Button 
          variant="contained" 
          onClick={handleAddContainer}
          disabled={!newContainerCode.trim()}
        >
          Agregar Contenedor
        </Button>
      </Box>

      {containers.map((container) => (
        <Box
          key={container.code}
          sx={{
            mb: 3,
            p: 2,
            border: "1px solid #e0e0e0",
            borderRadius: 1,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            {container.code}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: 2,
            }}
          >
            <TextField
              label="Fecha llegada a puerto"
              type="date"
              value={container.portArrivalDate}
              onChange={(e) =>
                handleContainerUpdate(
                  container.code,
                  "portArrivalDate",
                  e.target.value
                )
              }
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Fecha llegada a planta"
              type="date"
              value={container.plantArrivalDate}
              onChange={(e) =>
                handleContainerUpdate(
                  container.code,
                  "plantArrivalDate",
                  e.target.value
                )
              }
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Fecha retorno a patio"
              type="date"
              value={container.returnDate}
              onChange={(e) =>
                handleContainerUpdate(
                  container.code,
                  "returnDate",
                  e.target.value
                )
              }
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Recargos (USD)"
              type="number"
              value={container.delayCharges}
              onChange={(e) =>
                handleContainerUpdate(
                  container.code,
                  "delayCharges",
                  parseFloat(e.target.value)
                )
              }
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Box>
        </Box>
      ))}
      <Button variant="contained" fullWidth onClick={handleSave} sx={{ mt: 2 }}>
        Guardar
      </Button>
    </Box>
  );
};
