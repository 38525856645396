
import React from "react";
import { Chip, Typography, Box } from "@mui/material";

export default function Changelog() {
  return (
    <Box>
      <Typography variant="h4">Cambios 28/03/2025:</Typography>
      <ul>
        <li>General:</li>
        <ul>
          <li><Chip label="Mejora:" color="info" size="small" /> Se agregó visualización del nombre del archivo seleccionado al subir documentos</li>
          <li><Chip label="Mejora:" color="info" size="small" /> Se implementó validación para mostrar mensaje cuando no se han adjuntado todos los archivos</li>
        </ul>
        <li>Exportaciones:</li>
        <ul>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se habilitó el filtrado por empresa, factura y pedimento</li>
          <li><Chip label="Mejora:" color="info" size="small" /> Se agregó campo para número de factura</li>
        </ul>
        <li>Importaciones:</li>
        <ul>
          <li><Chip label="Mejora:" color="info" size="small" /> Se rediseñó la presentación visual de los días faltantes para mayor claridad</li>
        </ul>
      </ul>
      
      <Typography variant="h4">Cambios 27/03/2025:</Typography>
      <ul>
        <li>General:</li>
        <ul>
          <li><Chip label="Mejora:" color="info" size="small" /> Se creó un sistema generalizado de manejo de errores, se va a migrar cada módulo a ese sistema.</li>
          <li><Chip label="Mejora:" color="info" size="small" /> Se creó un componente general para subir archivos</li>
        </ul>
        <li>OC clientes:</li>
        <ul>
          <li><Chip label="Mejora:" color="info" size="small" /> Se migró al nuevo sistema de manejo de errores</li>
          <li><Chip label="Mejora:" color="info" size="small" /> Se agregaron nuevas reglas de negocio</li>
          <li><Chip label="Mejora:" color="info" size="small" /> Se actualizaron los campos existentes</li>
        </ul>
        <li>Exportaciones:</li>
        <ul>
          <li><Chip label="Nueva funcionalidad:" color="success" size="small" /> Se agregó la capacidad de adjuntar archivos a cada exportación</li>
        </ul>
        <li>Importaciones:</li>
        <ul>
          <li><Chip label="Mejora:" color="info" size="small" /> Se cambió al nuevo componente general para subir archivos</li>
        </ul>
      </ul>
      
      <Typography variant="h4">Cambios 18/03/2025:</Typography>
      <ul>
        <li>General:</li>
        <ul>
          <li><Chip label="Mejora:" color="info" size="small" /> Se eliminó la posibilidad de modificar valores numéricos con la rueda del mouse</li>
        </ul>
        <li>Ordenes de compra a Cliente (CPO):</li>
        <ul>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se separaron las CPO por empresa para mejor organización</li>
          <li><Chip label="Mejora:" color="info" size="small" /> Se agregó filtro por órdenes completadas y botón para mostrar/ocultar completados</li>
          <li><Chip label="Mejora:" color="info" size="small" /> Se redefinió el cálculo de "Days until MAD" para mayor precisión</li>
        </ul>
        <li>Producto Terminado:</li>
        <ul>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se corrigió la visualización de la lista de producto terminado</li>
        </ul>
      </ul>

      <Typography variant="h4">Cambios 14/03/2025:</Typography>
      <ul>
        <li>Pagos:</li>
        <ul>
          <li><Chip label="Nueva funcionalidad:" color="success" size="small" /> Se agregó plantilla PDF para visualización de pagos</li>
        </ul>
        <li>Contrarrecibos:</li>
        <ul>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se separaron las cuentas de proveedor en la plantilla PDF para mejor visualización</li>
        </ul>
        <li>Exportaciones:</li>
        <ul>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se corrigió la visualización de datos faltantes en la lista de exportaciones</li>
        </ul>
      </ul>


      <Typography variant="h4">Cambios 13/03/2025:</Typography>
      <ul>
        <li>Cuentas contables:</li>
        <ul>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se corrigió el error al intentar dar de alta una cuenta contable</li>
        </ul>
        <li>Productos:</li>
        <ul>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se corrigió problema al elegir tipo de producto donde se mantenía la categoría anterior</li>
        </ul>
        <li>Ordenes de compra:</li>
        <ul>
          <li><Chip label="Mejora:" color="info" size="small" /> Se actualizó formato de plantilla para optimizar espacio</li>
          <li><Chip label="Mejora:" color="info" size="small" /> Se agregó nombre del proveedor y orden de trabajo a la plantilla</li>
        </ul>
      </ul>



      <Typography variant="h4">Cambios 11/03/2025:</Typography>
      <ul>
        <li>Ordenes de compra a Cliente:</li>
        <ul>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se agregó la generación automática de clave al actualizar una CPO</li>
        </ul>
        <li>Ordenes de compra:</li>
        <ul>
          <li><Chip label="Mejora:" color="info" size="small" /> Se agregó soporte para unidad "MILLAR" con cálculo automático del precio dividido entre 1000</li>
          <li><Chip label="Mejora:" color="info" size="small" /> Se agregó campo "Tipo de Orden" con soporte para "Materia Prima"</li>
          <li><Chip label="Mejora:" color="info" size="small" /> Se agregó visualización de Órdenes de Trabajo relacionadas a la OC</li>
        </ul>
        <li>Contrarrecibos:</li>
        <ul>
          <li><Chip label="Mejora:" color="info" size="small" /> Se agregó método de pago</li>
          <li><Chip label="Mejora:" color="info" size="small" /> Se agregó Status "Cancelado" y capacidad de cancelar contrarrecibos</li>
          <li><Chip label="Nueva funcionalidad:" color="success" size="small" /> Se creó componente especial para tipos contrarrecibo: Teléfono, Arrendamiento y Luz</li>
          <li><Chip label="Mejora:" color="info" size="small" /> Se implementó ordenamiento por clave descendiente y se mejoró el proceso de programación</li>
        </ul>
      </ul>


      <Typography variant="h4">Cambios 07/03/2025:</Typography>
      <ul>
        <li>Exportaciones:</li>
        <ul>
          <li><Chip label="Corrección:" color="warning" size="small" /> Optimizar formulario de exportaciones para soportar múltiples artículos sin alentar el sistema</li>
          <li><Chip label="Corrección:" color="warning" size="small" /> Arreglar error crítico al intentar actualizar una exportación</li>
        </ul>
      </ul>


      <Typography variant="h4">Cambios 06/03/2025:</Typography>
      <ul>
        <li>Contrarrecibos:</li>
        <ul>
          <li><Chip label="Nueva funcionalidad:" color="success" size="small" /> Se habilitó la captura de contrarrecibos de meses anteriores dentro del año en curso</li>
          <li>Se eliminaron ceros iniciales en los montos</li>
          <li>Se convirtió el campo de tasa de impuesto a un desplegable con opciones de 0%, 8% y 16%</li>
        </ul>
        <li>Cuentas contables:</li>
        <ul>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se habilitó la edición del campo "cuentas contables" en el catálogo</li>
        </ul>
      </ul>


      <Typography variant="h4">Cambios 05/03/2025:</Typography>
      <ul>
        <li>Ordenes de compra:</li>
        <ul>
          <li>Se agregó botón de "Aprobar" y se aplicaron reglas de negocio</li>
          <li>Se agregó total en texto en la plantilla</li>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se arregló error al guardar ODC en algunos casos</li>
        </ul>
        <li>Exportaciones:</li>
        <ul>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se arregló el error al mostrar los campos desplegables</li>
        </ul>
        <li>Ordenes de compra a Cliente:</li>
        <ul>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se arregló el guardado de la orden de compra</li>
          <li> Se agregó campo DateCode</li>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se mejoró la visualización del indicador RFID</li>
        </ul>
      </ul>



      <Typography variant="h4">Cambios 28/02/2025:</Typography>
      <ul>
        <li>Ordenes de compra:</li>
        <ul>
          <li>Se agregó la observación dinámica de la orden de compra</li>
        </ul>
        <li>Importaciones:</li>
        <ul>
          <li><Chip label="Cambios:" color="info" size="small" /> Se reestructuró el módulo de importaciones</li>
        </ul>
      </ul>

      <Typography variant="h4">Cambios 27/02/2025:</Typography>
      <ul>
        <li>Contrarrecibo:</li>
        <ul>
          <li>Se agregó botón de exportar a PDF un contrarrecibo</li>
          <li><Chip label="Corrección:" color="warning" size="small" /> Se corrigió el formato de las cuentas contables de los contrarrecibos</li>
        </ul>
      </ul>

      <Typography variant="h4">Cambios 26/02/2025:</Typography>
      <ul>
        <li>Ordenes de compra:</li>
        <ul>
          <li><Chip label="Nueva funcionalidad:" color="success" size="small" /> La plantilla de ordenes de compra se puede imprimir con o sin costos</li>
          <li>Detalle ODC - Se puede aprobar y descargar una orden de compra</li>
          <li>Detalle ODC - Se Cambió el botón de "+" por "Agregar Concepto" </li>
          <li>Detalle ODC - El fijó el encabezado de la orden de compra, para que se mantenga visible aunque se agreguen muchos conceptos</li>
        </ul>
        <li>Proveedores:</li>
        <ul>
          <li><Chip label="Nueva funcionalidad:" color="success" size="small" />Checa si existe el proveedor (por RFC) en otra empresa, y copia sus datos al formulario actual</li>
          <li><Chip label="Nueva funcionalidad:" color="success" size="small" /> Info Bancaria de proveedor - Se puede editar y eliminar cuentas bancarias de los proveedores</li>
        </ul>
        <li>Contrarrecibo:</li>
        <ul>
          <li><Chip label="Nueva funcionalidad:" color="success" size="small" /> Se puede adjuntar un archivo al contrarrecibo (Factura, Nota de recepción y orden de compra)</li>
          <li><Chip label="Nueva funcionalidad:" color="success" size="small" /> Se creó la plantilla para exportar a PDF un contrarrecibo</li>
        </ul>
      </ul>
    </Box>
  );
} 