import React from "react";
import { Grid, Button } from "@mui/material";
import { FieldArray } from "formik";
import POItemRowMaterial from "../../molecules/PurchaseOrder/POItemRowMaterial";
import LGItemCellHeader from "../../atoms/Common/LGItemCellHeader";
import { useCompany } from "../../contexts/CompanyContext";

const PODetailTableMaterial = ({ items, activityIds }) => {
  const { selectedCompany } = useCompany();
  const companyId = selectedCompany?.id;
  return (
    <Grid container rowSpacing={0} columnSpacing={0}>
      <Grid item xs={1}>
        <LGItemCellHeader label={"Actividad"} />
      </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Cantidad"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Unidad"} />
    </Grid>
    <Grid item xs={2}>
      <LGItemCellHeader label={"Concepto"} />
    </Grid>
    <Grid item xs={3}>
      <LGItemCellHeader label={"Producto"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Precio unitario"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Orden de trabajo"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Tasa IVA"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Eliminar"} color={"error.main"} />
    </Grid>

    <FieldArray name="items">
      {({ push, remove }) => (
        <>
          {items.length > 0 &&
            items.map((item, index) => (
              <Grid item xs={12} key={index}>
                <POItemRowMaterial
                  index={index}
                  remove={remove}
                  activityIds={activityIds}
                  companyId={companyId}
                />
              </Grid>
            ))}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() =>
                push({
                  quantity: 0,
                  price: 0,
                  productId: null,
                  unitId: "",
                  concept: "",
                  workOrderId: "",
                  taxRate: 16,
                  subtotal: 0,
                })
              }
            >
              Agregar Concepto
            </Button>
          </Grid>
        </>
      )}
    </FieldArray>
    </Grid>
  );
};

export default PODetailTableMaterial; 