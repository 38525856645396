import axios from "axios";

export const handleError = (error) => {
  if (axios.isAxiosError(error)) {
    console.log(error);
    let errorTitle = "";
    let errorErrors = "";
    if (error.response.data.title) {
      errorTitle = error.response.data.title;
    }

    // if (error.response.data.errors) {
    //   let errorsArray = Object.values(error.response.data.errors);
    //   errorErrors = errorsArray.map((error) => error[0]).join("\r");
    // }
    return errorTitle + "\n" + errorErrors;
  }
};
