import React, { useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import {
  getCatalogue,
  getSubregimeList,
  getTaxRegimeList,
} from "../../api/common/catalogues";
import { getProviderByTaxId } from "../../api/providers";
import { useSnack } from "../../services/SnakbarProvider";
import { useCompany } from "../../contexts/CompanyContext";
import { LGNumber } from "../../atoms/Inputs/LGNumber.tsx";

const dropdownList = [
  {
    name: "providerScopeId",
    fetchFunction: getCatalogue,
    fetchParams: { CatalogueName: "PROVIDER_ORIGIN" },
    label: "Origen del proveedor",
  },
  {
    name: "legalEntityTypeId",
    fetchFunction: getCatalogue,
    fetchParams: { CatalogueName: "PROVIDER_TYPE" },
    label: "Tipo de proveedor",
  },
  {
    name: "regimeId",
    fetchFunction: getTaxRegimeList,
    label: "Régimen",
  },
  {
    name: "subregimeId",
    fetchFunction: getSubregimeList,
    label: "Subrégimen",
  },
  {
    name: "corporationTypeId",
    fetchFunction: getCatalogue,
    fetchParams: { CatalogueName: "CORPORATION_TYPE" },
    label: "Sociedad moral",
  },
];

const ProviderDetailsForm = ({ errors, touched }) => {
  const { values, setFieldValue } = useFormikContext();
  const { openSnack } = useSnack();
  const [loadingProvider, setLoadingProvider] = useState(false);
  const { selectedCompany } = useCompany();

  const fetchProviderByTaxId = async (taxId) => {
    setLoadingProvider(true);
    try {
      const provider = await getProviderByTaxId(taxId);
      if (provider.data.accounts?.some(account => account.companyId === selectedCompany.id)) {
        openSnack("Ya existe el proveedor en esta empresa", "warning");
        setLoadingProvider(false);
        return;
      }
      else {
        setFieldValue("provider", provider.data);
        setLoadingProvider(false);
        openSnack(`Proveedor encontrado en otra empresa`, "success");
      }
    } catch (error) {
      setLoadingProvider(false);
      openSnack("No se encontró el proveedor", "warning");

    }

  };


  useEffect(() => {
    if (!values.provider.code) {
      const legalNameParts = values.provider.legalName.split(" ");
      const firstWord = legalNameParts[0]?.slice(0, 7) || "";
      const secondWord = legalNameParts[1]?.slice(0, 3) || "";
      const taxIdPart = values.provider.taxId?.slice(0, 4) || "";
      const providerCode =
        `${firstWord}${secondWord}${taxIdPart}`.toUpperCase();
      setFieldValue("provider.code", providerCode);
    }
  }, [values.provider?.legalName, values.provider?.taxId, setFieldValue]);

  return (
    <Grid container spacing={2} alignItems={"flex-start"}>
      <Grid item xs={5}>
        <Field
          name="provider.legalName"
          label="Nombre o Razón social"
          as={TextField}
          fullWidth
          error={touched.provider?.legalName && !!errors.provider?.legalName}
        />
      </Grid>
      <Grid item xs={5}>
        <Field
          fullWidth
          name="provider.taxId"
          label="RFC / Tax ID"
          as={TextField}
          error={touched.provider?.taxId && !!errors.provider?.taxId}
        />
      </Grid>

      <Grid item xs={2}>
        <Button variant="contained" color="primary" type="submit" fullWidth sx={{ height: "100%" }} disabled={loadingProvider} onClick={async () => {
          const taxId = values.provider.taxId;
          await fetchProviderByTaxId(taxId);
        }}>
          {loadingProvider ? <CircularProgress size={20} /> : "Buscar por RFC"}
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Field
          fullWidth
          name="provider.code"
          label="Clave"
          as={TextField}
          error={touched.provider?.code && !!errors.provider?.code}
        />
      </Grid>

      {dropdownList.map((e) => (
        <Grid item xs={4} key={e.name}>
          <Field
            name={`provider.${e.name}`}
            label={e.label}
            as={DropdownSelector}
            fetchFunction={e.fetchFunction}
            fetchParams={e.fetchParams}
            error={touched.provider?.[e.name] && !!errors.provider?.[e.name]}
          />
        </Grid>
      ))}
      <Grid item xs={6}>
        <Field
          fullWidth
          name="provider.descripcion"
          label="Descripción"
          as={TextField}
          error={
            touched.provider?.descripcion && !!errors.provider?.descripcion
          }
        />
      </Grid>
      <Grid item xs={4}>
        <Field
          fullWidth
          name="provider.website"
          label="Sitio Web"
          as={TextField}
          error={touched.provider?.website && !!errors.provider?.website}
        />
      </Grid>
      <Grid item xs={2}>
        <Field
          fullWidth
          name="provider.creditDays"
          label="Días de Crédito"
          as={LGNumber}
          error={touched.provider?.creditDays && !!errors.provider?.creditDays}
        />
      </Grid>
    </Grid>
  );
};

export default ProviderDetailsForm;
