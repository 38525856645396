import React from "react";
import { FieldArray, Field, FastField } from "formik";
import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { Icon } from "@mui/material";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import MyDatePicker from "../../atoms/Common/MyDatePicker";
import CurrencyFieldText from "../../atoms/Inputs/CurrencyField";
import dayjs from "dayjs";
import { getCatalogue } from "../../api/common/catalogues";
import { getClientList } from "../../api/client";
import { getClientPurchaseOrders } from "../../api/clientPurchaseOrders";
import { LGNumber } from "../../atoms/Inputs/LGNumber.tsx";

const ExportItemsForm = ({ values, setFieldValue }) => {
  return (
    <FieldArray name="exportItems">
      {({ push, remove }) => (
        <Box>
          <Grid container spacing={2}>
            {values.exportItems?.map((_, index) => (
              <Grid item xs={12} key={index}>
                <Box sx={{ p: 2, border: "1px solid #ccc", borderRadius: 1 }}>
                  <Stack direction="row" justifyContent="space-between" mb={2}>
                    <Typography variant="h6">
                      Item de Exportación #{index + 1}
                    </Typography>
                    <IconButton onClick={() => remove(index)} color="error">
                      <Icon>delete</Icon>
                    </IconButton>
                  </Stack>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FastField
                        name={`exportItems.${index}.clientPurchaseOrderId`}
                        label="Orden de Compra Cliente"
                        as={DropdownSelector}
                        fetchFunction={getClientPurchaseOrders}
                        optionLabel={["code", "name"]}
                        optionSeparator={" | "}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        name={`exportItems.${index}.tariffFractionId`}
                        label="Fracción Arancelaria"
                        as={DropdownSelector}
                        fetchFunction={getCatalogue}
                        fetchParams={{ catalogueName: "TARIFF_FRACTION" }}
                        optionLabel={["code", "name"]}
                        optionSeparator={" | "}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        name={`exportItems.${index}.invoiceNo`}
                        label="Número de Factura"
                        as={TextField}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FastField
                        name={`exportItems.${index}.itemQuantity`}
                        label="Cantidad"
                        as={LGNumber}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FastField
                        name={`exportItems.${index}.palletQuantity`}
                        label="Cantidad de Pallets"
                        fullWidth
                        as={LGNumber}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FastField
                        fullWidth
                        name={`exportItems.${index}.invoiceAmount`}
                        label="Monto de Factura"
                        component={CurrencyFieldText}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FastField
                        fullWidth
                        name={`exportItems.${index}.netWeight`}
                        label="Peso Neto"
                        as={LGNumber}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        fullWidth
                        name={`exportItems.${index}.volume`}
                        label="Volumen (m3)"
                        as={LGNumber}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <MyDatePicker
                        fullWidth
                        slotProps={{ textField: { fullWidth: true } }}
                        label="Fecha de Factura"
                        value={
                          values.exportItems[index].invoiceDate
                            ? dayjs(values.exportItems[index].invoiceDate)
                            : null
                        }
                        onChange={(value) =>
                          setFieldValue(
                            `exportItems.${index}.invoiceDate`,
                            value
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={() =>
                  push({
                    clientPurchaseOrderId: "",
                    itemQuantity: 0,
                    palletQuantity: 0,
                    tariffFractionId: "",
                    invoiceNo: "",
                    invoiceDate: null,
                    invoiceAmount: 0,
                    netWeight: 0,
                  })
                }
              >
                <Icon>add</Icon> Agregar Item
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </FieldArray>
  );
};

export default ExportItemsForm;
