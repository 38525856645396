import React, { useEffect, useState } from "react";
import {
  deleteShipment,
  getShipmentList,
  exportShipments,
} from "../../api/imports";
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import LGModalCrud from "../LGCrud/LGModalCrud";
import ShipmentForm from "./ShipmentForm";
import dayjs from "dayjs";
import ShipmentFiles from "./ShipmentFilesTable";
import UpdateFileForm from "./ShipmentFilesTable";
import { lightBlue, orange, yellow } from "@mui/material/colors";
import { LGtheme } from "../../theme/theme";
import { CheckBox, FileDownload } from "@mui/icons-material";
import ShipmentExportModal from "../../molecules/Imports/ShipmentExportModal";
import LGModal from "../../molecules/LGModal";
import MyDatePicker from "../../atoms/Common/MyDatePicker";
import MonthPicker from "../../atoms/Inputs/MonthPicker";
import YearPicker from "../../atoms/Inputs/YearPicker";
import { ManageShipmentContainers } from "./ManageShipmentContainers.tsx";

export default function ShipmentTable({ companyId }) {
  const [showCompleted, setShowCompleted] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const setStatusColor = (statusCode) => {
    switch (statusCode) {
      case "EMBARCADO":
        return "default";
      case "EN_PUERTO":
        return "warning";
      case "EN_TRANSITO_LOCAL":
        return "info";
      case "ENTREGADO":
        return "success";
    }
  };

  const handleChange = () => {
    setShowCompleted(!showCompleted);
  };

  const handleExport = async (params) => {
    try {
      const response = await exportShipments(params);
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `shipments_export_${
        new Date().toISOString().split("T")[0]
      }.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setExportModalOpen(false);
    } catch (error) {
      console.error("Error exporting shipments:", error);
    }
  };

  const handleDateChange = () => {
    let startDate = null;
    let endDate = null;
    if (month === 12) {
      startDate = dayjs(new Date(year, 0, 1)).format("YYYY-MM-DD");
      endDate = dayjs(new Date(year + 1, 0, 0)).format("YYYY-MM-DD");
    } else {
      startDate = dayjs(new Date(year, month+1, 1)).format("YYYY-MM-DD");
      endDate = dayjs(new Date(year, month + 2, 0)).format("YYYY-MM-DD");
    }
    setStartDate(startDate);
    setEndDate(endDate);
  };  

  return (
    <Box width={"100%"}>
      <LGModal
        open={exportModalOpen}
        handleClose={() => setExportModalOpen(false)}
        header="Exportar Embarques"
      >
        <ShipmentExportModal companyId={companyId} onSubmit={handleExport} />
      </LGModal>

      <Grid container direction="row" spacing={2} mb={2}>
        <Grid item>
          <Button onClick={handleChange}>
            {showCompleted ? "Mostrar completados" : "Ocultar completados"}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => setExportModalOpen(true)}
            variant="contained"
            startIcon={<FileDownload />}
          >
            Exportar CSV
          </Button>
        </Grid>
        <Grid item>
          <MonthPicker value={month} label="Mes" onChange={(e) => setMonth(e.target.value)} />
        </Grid>
        <Grid item>
          <YearPicker value={year} label="Año" onChange={(e) => setYear(e.target.value)} />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleDateChange}>Buscar</Button>
        </Grid>
      </Grid>

      <LGModalCrud
        props={{
          actions: ["create", "update", "delete", "detail"],
          data: { companyId, showCompleted, startDate, endDate },
          fetchFunction: getShipmentList,
          deleteFunction: deleteShipment,
          detailMenu: [
            {
              label: "Documentos",
              handler: () => {},
              header: "Documentos",
              body: <UpdateFileForm />,
            },
            {
              label: "Contenedores",
              handler: () => {},
              header: "Contenedores",
              body: <ManageShipmentContainers />,
            },
          ],
          columns: [
            {
              field: "ref",
              headerName: "Ref",
              flex: 0.2,
            },
            {
              field: "provider",
              headerName: "Proveedor",
              flex: 0.2,
              valueGetter: (value, row) => {
                return row.provider ? `${row.provider.code}` : "";
              },
            },
            {
              field: "portArrivalEta",
              headerName: "Fecha de llegada estimada",
              flex: 0.2,
            },
            {
              field: "remainingDays",
              headerName: "Dias restantes",
              flex: 0.2,
              renderCell: (params) => {
                const remainingDays =
                  -1 * dayjs().diff(params.row.portArrivalEta, "d");
                return (
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Chip
                      size="small"
                      label={params.row.status.code === "ENTREGADO" ? "-" : remainingDays}
                      color={
                        params.row.status.code === "ENTREGADO"
                          ? "default"
                          : remainingDays > 5
                          ? "success"
                          : remainingDays > 0
                          ? "warning"
                          : "error"
                      }
                    />
                  </Box>
                );
              },
            },

            {
              field: "containers",
              headerName: "Contenedores",
              flex: 0.3,
              renderCell: (params) => (
                <Stack direction={"column"}>
                  {params.value.map((e) => (
                    <Typography variant="body2" key={e.id}>
                      {e.code} | {e.description}
                    </Typography>
                  ))}
                </Stack>
              ),
            },
            {
              field: "status",
              headerName: "Estatus",
              flex: 0.2,
              renderCell: (params) => (
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Chip
                    size="small"
                    label={params.row.status.name}
                    color={setStatusColor(params.row.status.code)}
                  />
                </Box>
              ),
            },
          ],
          addModalData: {
            header: "Agregar Embarque",
            body: <ShipmentForm companyId={companyId}/>,
          },
          editModalData: {
            header: "Editar Embarque",
            body: <ShipmentForm companyId={companyId}/>,
          },
        }}
      />
    </Box>
  );
}
