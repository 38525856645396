import React, { useEffect, useState } from "react";
import {
    Grid,
    IconButton,
    TextField,
    MenuItem,
    Autocomplete,
    FormControl,
    InputLabel,
    Select,
} from "@mui/material";
import { FastField, Field, useFormikContext } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import { getCatalogue, getCatalogueEntryById } from "../../api/common/catalogues";
import { getProductList } from "../../api/products";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import { getExpenseAccountList } from "../../api/accounting";
import { LGNumber } from "../../atoms/Inputs/LGNumber.tsx";

const POItemRowMaterial = ({ index, remove, activityIds, companyId }) => {
    const { setFieldValue, values, touched, errors } = useFormikContext();
    const [productOptions, setProductOptions] = useState([]);
    const { openSnack } = useSnack();
    const [selectedUnit, setSelectedUnit] = useState(null);

    const fetchProductOptions = async (inputValue) => {
        try {
            const res = await getProductList({
                code: inputValue ? inputValue.toUpperCase() : null,
                ExpenseAccountIds: activityIds,
                companyId: companyId,
            });
            setProductOptions(res.data);
        } catch (e) {
            openSnack(handleError(e), "error");
        }
    };

    const handleUnitChange = async (e, value) => {
        const unitId = value.id;
        setFieldValue(`items.${index}.unitId`, unitId);
        try {
            const unitData = await getCatalogueEntryById(unitId);
            setSelectedUnit(unitData.data);
        } catch (e) {
            openSnack(handleError(e), "error");
        }
    };

    useEffect(() => {
        if (selectedUnit?.code === "millar") {
            const quantity = values.items[index].quantity;
            const price = values.items[index].price / 1000;
            const subtotal = quantity * price;
            setFieldValue(`items.${index}.subtotal`, subtotal);
        }
        else {
            const quantity = values.items[index].quantity;
            const price = values.items[index].price;
            const subtotal = quantity * price;
            setFieldValue(`items.${index}.subtotal`, subtotal);
        }
    }, [values.items[index].quantity, values.items[index].price, selectedUnit]);

    return (
        <Grid container spacing={0}>
            <Grid item xs={1}>
                <FastField
                    as={DropdownSelector}
                    fetchFunction={getExpenseAccountList}
                    fetchParams={{ ExpenseAccountIds: activityIds }}
                    optionLabel={["completeContpaqiCode", "name"]}
                    optionSeparator=" | "
                    name={`items.${index}.expenseAccountId`}
                    fullWidth
                    size="small"
                    margin="none"
                />
            </Grid>
            <Grid item xs={1}>
                <FastField
                    as={TextField}
                    name={`items.${index}.quantity`}
                    fullWidth
                    size="small"
                    margin="none"
                    type="number"
                />
            </Grid>
            <Grid item xs={1}>
                <FastField
                    as={DropdownSelector}
                    fetchFunction={getCatalogue}
                    fetchParams={{ CatalogueName: "UNIT" }}
                    optionLabel="code"
                    name={`items.${index}.unitId`}
                    fullWidth
                    size="small"
                    margin="none"
                    onChange={handleUnitChange}
                />
            </Grid>
            <Grid item xs={2}>
                <FastField
                    as={TextField}
                    name={`items.${index}.concept`}
                    fullWidth
                    multiline
                    size="small"
                    margin="none"
                />
            </Grid>
            <Grid item xs={3}>
                <Autocomplete
                    size="small"
                    margin="none"
                    options={productOptions}
                    getOptionLabel={(option) => option.code || ""}
                    value={
                        productOptions.find(
                            (option) => option.id === values.items?.[index]?.productId
                        ) || null
                    }
                    onInputChange={(event, value) => fetchProductOptions(value)}
                    onChange={(event, value) => {
                        setFieldValue(`items.${index}.productId`, value ? value.id : null);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={
                                touched.items?.[index]?.productId &&
                                Boolean(errors.items?.[index]?.productId)
                            }
                            helperText={
                                touched.items?.[index]?.productId &&
                                errors.items?.[index]?.productId
                            }
                        />
                    )}
                />
            </Grid>
            <Grid item xs={1}>
                <FastField
                    as={LGNumber}
                    name={`items.${index}.price`}
                    fullWidth
                    size="small"
                    margin="none"
                />
            </Grid>
            <Grid item xs={1}>
                <FastField
                    as={TextField}
                    name={`items.${index}.workOrderId`}
                    fullWidth
                    size="small"
                    margin="none"
                />
            </Grid>
            <Grid item xs={1}>
                <FastField name={`items.${index}.taxRate`}>
                    {({ field }) => (
                        <FormControl fullWidth size="small" margin="none" variant="outlined">
                            <Select {...field} label="Tax Rate">
                                {[0.0, 8.0, 16.0].map((rate) => (
                                    <MenuItem key={rate} value={rate}>
                                        {rate}%
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </FastField>
            </Grid>
            <Grid item xs={1}>
                <IconButton color="error" onClick={() => remove(index)}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default POItemRowMaterial; 