import React, { useEffect, useState } from "react";
import { Box, Grid, Menu, MenuItem, Typography } from "@mui/material";

import {
  deleteShipmentFile,
  downloadShipmentFile,
  getShipmentFileList,
  uploadShipmentFiles,
} from "../../api/imports";
import LGModalCrud from "../LGCrud/LGModalCrud";
import { useSnack } from "../../services/SnakbarProvider";
import FileUpload from "../../screens/Lab/mockAddFile";
import { handleError } from "../../services/errorHandler";
import GenericFileUpload from "../Common/GenericFileUpload";

const fileOptions = [
  "Guia de embarque",
  "Lista de empaque",
  "Factura Proveedor",
  "Pedimento",
  "Cuenta de Gastos (ZIP)",
  "Factura de demora",
  "Comprobante de pago"
];

export default function UpdateFileForm({ props }) {
  const { id } = props;
  const { openSnack } = useSnack();

  const handlePost = async (data) => {
    // setModalOpen(false);
    // const payload = { ...data };
    // const res = await addProviderAccounts(payload);
  };

  const handleUpdate = async (id, data) => { };

  const handleDelete = async (id) => {
    console.log("DELETE");

    // const res = await deleteProviderAccounts(id);
  };

  const handleOpenDetailClick = () => {
    console.log("Open Detail");
  };

  const handleFileDownload = async (id) => {
    try {
      const response = await downloadShipmentFile(id);
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "downloadedFile";

      if (
        contentDisposition &&
        contentDisposition.indexOf("attachment") !== -1
      ) {
        // Extract filename from Content-Disposition header
        const matches = contentDisposition.match(/filename="([^"]*)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      // Get the file extension from the filename if not available in content-disposition
      const fileExtension = fileName.split(".").pop();
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      // Set the download attribute with the filename including extension
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  // const handleFileDownload = async () => {
  //   console.log("FILE DOWNLOAD");
  // };

  const handleOpenContactClick = () => {
    console.log("Open Detail");
  };
  const handleOpenBankAccountClick = () => {
    console.log("Open Detail");
  };
  const handleOpenActivitiesClick = () => {
    console.log("Open Detail");
  };

  return (
    <>
      <Box width={"100%"}>
        {id && (
          <LGModalCrud
            props={{
              actions: ["create", "download", "delete"],
              data: { shipmentId: id },
              fetchFunction: getShipmentFileList,
              deleteFunction: deleteShipmentFile,
              downloadFunction: handleFileDownload,
              detailMenu: [
                {
                  label: "Descargar",
                  handler: handleFileDownload,
                  header: "Descargar",
                  body: <FileUpload />,
                },
              ],
              columns: [
                {
                  field: "fileConcept",
                  headerName: "Nombre del archivo",
                  flex: 0.5,
                },
              ],
              addModalData: {
                header: "Agregar Documentos",
                body: (
                  <GenericFileUpload
                    fileNameOptions={fileOptions}
                    uploadFunction={uploadShipmentFiles}
                    id={id}
                  />
                ),
              },
              editModalData: {
                header: "Editar Proveedor",
                body: <p>Formulario editar archivo</p>,
              },
            }}
          />
        )}
      </Box>
    </>
  );
}
