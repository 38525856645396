import React, { useEffect, useState, useCallback } from "react";
import { Formik, Form, Field, FastField } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Autocomplete,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import { getCompanyList } from "../../api/enterprise";
import { getProviderList } from "../../api/providers";
import { getCatalogue, getStatusCatalogue } from "../../api/common/catalogues";
import { getClientList } from "../../api/client";
import { useSnack } from "../../services/SnakbarProvider";
import {
  createClientPurchaseOrder,
  getClientPurchaseOrderDetail,
  updateClientPurchaseOrder,
} from "../../api/clientPurchaseOrders";
import { handleError } from "../../services/errorHandler";
import { getFinishedGoodList } from "../../api/finishedGoods";
import { getCurrencyList } from "../../api/common/currencies";
import { LGNumber } from "../../atoms/Inputs/LGNumber.tsx";
import debounce from "lodash/debounce";

const validationSchema = Yup.object({
  clientId: Yup.number().required("Campo requerido"),
  code: Yup.string().required("Campo requerido"),
  productionStatusId: Yup.number(),
  shipmentStatusId: Yup.number(),
  finishedGoodId: Yup.number(),
  receptionDate: Yup.date().nullable(),
  requestedDeliveryDate: Yup.date().nullable(),
  firstMad: Yup.date().nullable(),
  lastMad: Yup.date().nullable(),
  quantity: Yup.number(),
  rfidSupplierId: Yup.number().when("rfid", {
    is: true,
    then: Yup.number().required("Campo requerido cuando RFID está habilitado"),
  }),
  destinationId: Yup.number(),
  unitPrice: Yup.number()
    .required("Campo requerido")
    .min(0, "Debe ser 0 o mayor"),
  currencyId: Yup.number().required("Campo requerido"),
  workOrder: Yup.string().required("Campo requerido"),
});

const initialValues = {
  clientId: "",
  destinationId: null,
  productionStatusId: null,
  shipmentStatusId: null,
  workOrder: "",
  code: "",
  finishedGoodId: null,
  line: "",
  dateCode: "",
  receptionDate: null,
  requestedDeliveryDate: null,
  firstMad: null,
  lastMad: null,
  quantity: null,
  rfid: false,
  rfidSupplierId: null,
  unitPrice: "",
  comments: "",
};

const CPOForm = ({ id, onSubmit, companyId }) => {
  const formMode = id ? "edit" : "insert";
  const [loading, setLoading] = useState(false);
  const { openSnack } = useSnack();
  const [formData, setFormData] = useState(initialValues);
  const [finishedGoodsOptions, setFinishedGoodsOptions] = useState([]);
  const [finishedGoodsLoading, setFinishedGoodsLoading] = useState(false);
  const [finishedGoodsCache, setFinishedGoodsCache] = useState({});
  const [providerOptions, setProviderOptions] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getClientPurchaseOrderDetail(id);
      setFormData(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
    setLoading(false);
  };

  const fetchFinishedGoodsOptions = useCallback(
    debounce(async (inputValue) => {
      if (!inputValue) {
        try {
          setFinishedGoodsLoading(true);
          const res = await getFinishedGoodList({});
          setFinishedGoodsOptions(res.data);
        } catch (e) {
          openSnack(handleError(e), "error");
        } finally {
          setFinishedGoodsLoading(false);
        }
        return;
      }

      // Check cache first
      if (finishedGoodsCache[inputValue]) {
        setFinishedGoodsOptions(finishedGoodsCache[inputValue]);
        return;
      }

      try {
        setFinishedGoodsLoading(true);
        const res = await getFinishedGoodList({
          name: inputValue.toUpperCase(),
        });
        setFinishedGoodsOptions(res.data);
        // Update cache
        setFinishedGoodsCache((prev) => ({
          ...prev,
          [inputValue]: res.data,
        }));
      } catch (e) {
        openSnack(handleError(e), "error");
      } finally {
        setFinishedGoodsLoading(false);
      }
    }, 300),
    []
  );

  const fetchProviderOptions = async (inputValue) => {
    try {
      const res = await getProviderList({
        code: inputValue ? inputValue.toUpperCase() : null,
      });
      setProviderOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let payload = { ...values };
      payload.companyId = companyId;
      // Remove null values from payload
      payload = Object.fromEntries(
        Object.entries(payload).filter(([_, value]) => value !== null)
      );
      if (formMode == "insert") {
        payload.lastMad = values.firstMad;
        const submitForm = await createClientPurchaseOrder(payload);
      }
      if (formMode == "edit") {
        const submitForm = await updateClientPurchaseOrder(id, payload);
      }
      openSnack("CPO creado correctamente", "success");
      onSubmit();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFinishedGoodsOptions();
    fetchProviderOptions();
    if (formMode == "edit") {
      fetchData();
    } else {
      setFormData(initialValues);
    }
  }, []);

  return (
    <Formik
      initialValues={{ ...formData }}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, touched, errors, handleChange, setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Datos Generales</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="clientId"
                label="Cliente"
                as={DropdownSelector}
                fetchFunction={getClientList}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                id="finishedGoodId"
                name="finishedGoodId"
                options={finishedGoodsOptions}
                getOptionLabel={(option) => `${option.code} | ${option.name}`}
                value={
                  finishedGoodsOptions.find(
                    (option) => option.id === values.finishedGoodId
                  ) || null
                }
                onInputChange={(event, value) => {
                  fetchFinishedGoodsOptions(value);
                }}
                onChange={(event, value) => {
                  setFieldValue("finishedGoodId", value ? value.id : "");
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={finishedGoodsLoading}
                blurOnSelect
                disablePortal
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Producto Terminado"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {finishedGoodsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Field
                fullWidth
                name="code"
                label="Orden de Compra"
                as={TextField}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code && errors.code}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                name="workOrder"
                label="Orden de Trabajo"
                as={TextField}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Field
                fullWidth
                name="quantity"
                label="Cantidad"
                as={LGNumber}
                error={touched.quantity && Boolean(errors.quantity)}
                helperText={touched.quantity && errors.quantity}
              />
            </Grid>

            <Grid item xs={8} md={4}>
              <Field
                fullWidth
                name="unitPrice"
                label="Precio Unitario"
                as={LGNumber}
                error={touched.unitPrice && Boolean(errors.unitPrice)}
                helperText={touched.unitPrice && errors.unitPrice}
              />
            </Grid>

            <Grid item xs={4} md={4}>
              <Field
                name="currencyId"
                label="Moneda"
                as={DropdownSelector}
                fetchFunction={getCurrencyList}
                optionLabel={["symbol", "name"]}
                optionSeparator=" | "
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Field
                fullWidth
                name="receptionDate"
                label="Fecha de Recepción"
                type="date"
                as={TextField}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Field
                fullWidth
                name="requestedDeliveryDate"
                label="Fecha de Entrega Solicitada"
                type="date"
                as={TextField}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Field
                disabled={formMode == "edit"}
                fullWidth
                name="firstMad"
                label="Primera MAD"
                type="date"
                as={TextField}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            {formMode == "edit" && (
              <Grid item xs={12} md={4}>
                <Field
                  fullWidth
                  name="lastMad"
                  label="Última MAD"
                  type="date"
                  as={TextField}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}

            <Grid item xs={12} md={2}>
              <Field
                as={FormControlLabel}
                control={
                  <Switch
                    checked={values.rfid}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setFieldValue("rfid", isChecked);
                      if (!isChecked) {
                        setFieldValue("rfidSupplierId", null);
                      }
                    }}
                    name="rfid"
                  />
                }
                label="RFID"
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <Autocomplete
                id="rfidSupplierId"
                name="rfidSupplierId"
                options={providerOptions}
                disabled={!values.rfid}
                getOptionLabel={(option) => option.code || ""}
                value={
                  providerOptions.find(
                    (option) => option.id === values.rfidSupplierId
                  ) || null
                }
                onInputChange={(event, value) => fetchProviderOptions(value)}
                onChange={(event, value) => {
                  setFieldValue("rfidSupplierId", value ? value.id : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Proveedor RFID"
                    error={
                      touched.rfidSupplierId && Boolean(errors.rfidSupplierId)
                    }
                    helperText={touched.rfidSupplierId && errors.rfidSupplierId}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <FastField
                name="destinationId"
                label="Destino"
                as={DropdownSelector}
                fetchFunction={getCatalogue}
                fetchParams={{ CatalogueName: "EXPORT_DESTINATIONS" }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Field
                fullWidth
                name="dateCode"
                label="Date Code"
                as={TextField}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Field
                name="productionStatusId"
                label="Estatus de Producción"
                as={DropdownSelector}
                fetchFunction={getStatusCatalogue}
                fetchParams={"CPO_PRODUCTION"}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Field
                name="shipmentStatusId"
                label="Estatus de Envío"
                as={DropdownSelector}
                fetchFunction={getStatusCatalogue}
                fetchParams={"CPO_SHIPMENT"}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                fullWidth
                name="comments"
                label="Comentarios"
                multiline
                rows={4}
                as={TextField}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained">
                {formMode === "edit" ? "Actualizar" : "Añadir"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CPOForm;
