import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form, Field, FieldArray, FastField } from "formik";
import * as Yup from "yup";
import { getCatalogue } from "../../api/common/catalogues";
import { getExpenseAccountList } from "../../api/accounting";
import { useSnack } from "../../services/SnakbarProvider";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import DeleteIcon from "@mui/icons-material/Delete";
import { addProduct, getProductById, updateProduct } from "../../api/products";
import { handleError } from "../../services/errorHandler";
import ProductFormHeader from "./ProductFormHeader.tsx";
import ProductFormProperties from "./ProductFormProperties.tsx";
import ProductPaperForm from "./ProductPaperForm.tsx";

const validationSchema = Yup.object().shape({
  originalCode: Yup.string().nullable(),
  productTypeId: Yup.number().required(
    "El campo tipo de producto es obligatorio"
  ),
  productCategoryId: Yup.number().required(
    "El campo categoría de producto es obligatorio"
  ),
  description: Yup.string(),
  expenseAccountId: Yup.number().required(
    "El campo cuenta de gastos es obligatorio"
  ),
  props: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().nullable(),
      value: Yup.string().nullable(),
    })
  ),
});

const initialValues = {
  originalCode: "",
  productTypeId: "",
  props: [
    { key: "", value: "" },
    { key: "", value: "" },
    { key: "", value: "" },
    { key: "", value: "" },
    { key: "", value: "" },
  ],
  productCategoryId: "",
  description: "",
  expenseAccountId: "",
};

const ProductForm = ({ id, onSubmit, companyId }) => {
  const formMode = id ? "edit" : "insert";
  const [loading, setLoading] = useState(false);
  const [productType, setProductType] = useState(null);
  const [formData, setFormData] = useState(initialValues);
  const { openSnack } = useSnack();

  const handleSubmit = async (values) => {
    try {
      if (formMode == "insert") {
        let payload = { ...values };
        payload.CompanyId = companyId;
        payload.props = payload.props.reduce((acc, curr) => {
          acc[curr.key] = typeof curr.value === 'string' ? curr.value.toUpperCase() : curr.value;
          return acc;
        }, {});
        await addProduct(payload);
        openSnack("Producto guardado satisfactoriamente", "success");
        onSubmit();
      } else if (formMode == "edit") {
        let payload = { ...values };
        payload.props = payload.props.reduce((acc, curr) => {
          acc[curr.key] = curr.value;
          return acc;
        }, {});
        await updateProduct(id, payload);
        openSnack("Producto actualizado satisfactoriamente", "success");
        onSubmit();
      }
    } catch (e) {
      openSnack(e, "error");
    }

    console.log(values);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getProductById(id);
      const props = Object.entries(res.data.props).map(([key, value]) => ({
        key,
        value,
      }));
      const payload = { ...res.data, props };
      setFormData(payload);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (formMode == "edit") {
      fetchData();
    } else {
      setFormData(initialValues);
    }
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <Formik
      initialValues={{ ...formData }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, handleBlur, touched, errors }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h4">Datos generales del producto</Typography>
              <ProductFormHeader companyId={companyId} setProductType={setProductType} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4">Propiedades del producto</Typography>
              {productType === "PP" ? (
                <ProductPaperForm />
              ) : (
                <ProductFormProperties />
              )}
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ProductForm;
