import React from 'react';
import { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import { forwardRef } from 'react';

export interface LGNumberProps extends Omit<TextFieldProps, 'type'> {
  // Add any additional props specific to LGNumber here
}

export const LGNumber = forwardRef<HTMLInputElement, LGNumberProps>((props, ref) => {
  const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    event.target.blur();
  };

  return (
    <TextField
      {...props}
      type="number"
      onWheel={handleWheel}
      ref={ref}
    />
  );
});

LGNumber.displayName = 'LGNumber'; 