import React from "react";
import {
  Grid,
  TextField,
  IconButton,
  Icon,
  Button,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { FieldArray, Field } from "formik";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import { getExpenseAccountList } from "../../api/accounting";
import { getProviderAccountsList } from "../../api/providers";
import LGItemCellHeader from "../../atoms/Common/LGItemCellHeader";

const RAItems = () => {
  return (
    <Grid container>
      <Grid item xs={3}>
        <LGItemCellHeader label={"Cuenta contable"} />
      </Grid>
      <Grid item xs={3}>
        <LGItemCellHeader label={"Cuenta del proveedor"} />
      </Grid>
      <Grid item xs={2}>
        <LGItemCellHeader label={"Monto"} />
      </Grid>
      <Grid item xs={1}>
        <LGItemCellHeader label={"Tasa"} />
      </Grid>
      <Grid item xs={2}>
        <LGItemCellHeader label={"Porcentaje"} />
      </Grid>
      <Grid item xs={1}>
        <LGItemCellHeader label={"Eliminar"} color={"error.main"} />
      </Grid>

      <FieldArray name="items">
        {({ push, remove, form }) => {
          const { id, activities } = form.values.selectedProvider;

          const expenseAccountIds = activities
            ? activities.map((activity) => activity.expenseAccountId)
            : [];

          const totalAmount = form.values.items.reduce(
            (sum, item) =>
              sum + (parseFloat(item.amount * (1 + item.taxRate / 100)) || 0),
            0
          );

          return (
            <Grid item xs={12}>
              {form.values.items.map((item, index) => {
                const weight = totalAmount
                  ? (parseFloat(item.amount * (1 + item.taxRate / 100)) || 0) /
                    totalAmount
                  : 0;

                return (
                  <Grid container item xs={12} key={index} alignItems="center">
                    <Grid item xs={3}>
                      <Field
                        name={`items.${index}.expenseAccountId`}
                        as={DropdownSelector}
                        fetchFunction={getExpenseAccountList}
                        fetchParams={{
                          ExpenseAccountIds: expenseAccountIds ?? null,
                          pageSize: 1000,
                        }}
                        optionLabel={["name","contpaqiCode"]}
                        optionSeparator=" | "
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name={`items.${index}.providerAccountId`}
                        as={DropdownSelector}
                        fetchFunction={getProviderAccountsList}
                        fetchParams={{
                          providerId: id,
                        }}
                        optionLabel={"contpaqiAccountNo"}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field name={`items.${index}.amount`}>
                        {({ field }) => (
                          <TextField
                            fullWidth
                            type="number"
                            {...field}
                            variant="outlined"
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={1}>
                      <Field name={`items.${index}.taxRate`}>
                        {({ field }) => (
                          <Select
                            fullWidth
                            {...field}
                            variant="outlined"
                          >
                            <MenuItem value={0}>0%</MenuItem>
                            <MenuItem value={8}>8%</MenuItem>
                            <MenuItem value={16}>16%</MenuItem>
                          </Select>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ ml: 1 }} variant="body2">
                        {isNaN(weight) ? "0%" : `${(weight * 100).toFixed(2)}%`}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={() => remove(index)} color="error">
                        <Icon>delete</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() =>
                    push({
                      expenseAccountId: "",
                      providerAccountId: "",
                      amount: null,
                      taxRate: 0,
                    })
                  }
                  color="primary"
                >
                  <Icon>add</Icon>
                </Button>
              </Grid>
            </Grid>
          );
        }}
      </FieldArray>
    </Grid>
  );
};

export default RAItems;
