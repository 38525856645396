import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import MyDatePicker from "../../atoms/Common/MyDatePicker";
import { getReceiptAcknowledgements, programPayments } from "../../api/reciptAcknowledgement";

export default function RAExportModal({ companyId, onSubmit }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [codeStart, setCodeStart] = useState(null);
  const [codeEnd, setCodeEnd] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    onSubmit({
      startDate: startDate?.format("YYYY-MM-DD"),
      endDate: endDate?.format("YYYY-MM-DD"),
      codeStart,
      codeEnd,
      companyId,
    });
  };

  const handleProgramPayments = async () => {
    try {
      setLoading(true);
      
      // Get IDs based on either date range or code range
      const criteria = {
        companyId,
        ...(startDate && endDate ? {
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD")
        } : {}),
        ...(codeStart && codeEnd ? {
          codeStart,
          codeEnd
        } : {})
      };

      // Validate that at least one filter is set
      if (!startDate && !endDate && !codeStart && !codeEnd) {
        throw new Error('Please set either a date range or code range');
      }

      const ids = await getReceiptAcknowledgements(criteria);
      
      if (!ids.length) {
        throw new Error('No se encontraron contrarrecibos para los criterios seleccionados');
      }

      await programPayments(ids);
      onSubmit(); // Close modal after success
    } catch (error) {
      console.error('Error programming payments:', error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const hasDateRange = startDate && endDate;
  const hasCodeRange = codeStart && codeEnd;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Por fecha</Typography>
        </Grid>
        <Grid item xs={6}>
          <MyDatePicker
            fullWidth
            slotProps={{ textField: { fullWidth: true } }}
            label="Fecha inicial"
            value={startDate}
            onChange={(value) => setStartDate(value)}
          />
        </Grid>
        <Grid item xs={6}>
          <MyDatePicker
            fullWidth
            slotProps={{ textField: { fullWidth: true } }}
            label="Fecha final"
            value={endDate}
            onChange={(value) => setEndDate(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Por rango de código</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Código inicial"
            value={codeStart}
            onChange={(e) => setCodeStart(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Código final"
            value={codeEnd}
            onChange={(e) => setCodeEnd(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            // disabled={!startDate || !endDate || !codeStart || !codeEnd}
          >
            Exportar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleProgramPayments}
            disabled={loading || (!hasDateRange && !hasCodeRange)}
          >
            {loading ? 'Programando...' : 'Programar'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
