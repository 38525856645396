import { api } from "..";

const endpoint = "/api/receipt-acknowledgements";

// RA HEADER
export const getReceiptAcknowledgements = (queryParams) => {
  return api.get(`${endpoint}`, { params: queryParams });
};

export const getReceiptAcknowledgementDetail = (id) => {
  return api.get(`${endpoint}/${id}`);
};

export const addReceiptAcknowledgement = (data) => {
  return api.post(`${endpoint}`, data);
};

export const updateReceiptAcknowledgement = (id, data) => {
  return api.put(`${endpoint}/${id}`, data);
};

export const deleteReceiptAcknowledgement = (id) => {
  return api.delete(`${endpoint}/${id}`);
};

// RA ITEMS

export const exportReceiptAcknowledgementTxt = async (params) => {
  const response = await api.get(`${endpoint}/export/txt`, {
    params,
    responseType: 'blob',
  });
  return response;
};

// PROGRAM PAYMENTS
export const programPayments = async (data) => {
  return await api.post(`${endpoint}/program-payments`, data);
};

// CANCEL RECEIPT ACKNOWLEDGEMENTS
export const cancelReceiptAcknowledgements = async (data) => {
  return await api.post(`${endpoint}/cancel`, data);
};

// EXPORT TO PDF
export const exportRA = (id) => {
  return api.get(`${endpoint}/${id}/pdf`, {
    responseType: "blob",
    headers: { Accept: "*/*" },
  });
};

// RA FILES
export const getRAFileList = (data) => {
  console.log(data);
  const { raId } = data;
  return api.get(`${endpoint}/${raId}/files`);
};

export const downloadRAFile = (fileId) => {
  return api.get(`${endpoint}/files/${fileId}/download`, {
    responseType: "blob",
    headers: { Accept: "*/*" },
  });
};

export const deleteRAFile = (fileId) => {
  return api.delete(`${endpoint}/files/${fileId}`);
};

export const uploadRAFiles = (data, file) => {
  const formData = new FormData();
  formData.append("metadata", JSON.stringify(data));
  formData.append("file", file, file.name);

  return api.post(`${endpoint}/${data.receiptId}/files/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

