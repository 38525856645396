import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useSnack } from "../../services/SnakbarProvider";
import RAHeader from "../../molecules/ReceiptAcknowledgements/RAHeader";
import RAItems from "../../molecules/ReceiptAcknowledgements/RAItems";
import RASpecialItems from "../../molecules/ReceiptAcknowledgements/RASpecialItems";
import { Button, Divider, Stack, Typography } from "@mui/material";
import RASummary from "../../molecules/ReceiptAcknowledgements/RASummary";
import {
  addReceiptAcknowledgement,
  getReceiptAcknowledgementDetail,
  updateReceiptAcknowledgement,
} from "../../api/reciptAcknowledgement";
import { handleError } from "../../services/errorHandler";
import { getProviderDetail } from "../../api/providers";

const ReceiptAcknowledgementForm = ({ id, onSubmit, companyId }) => {
  const formMode = id ? "edit" : "insert";
  const [loading, setLoading] = useState(false);
  const { openSnack } = useSnack();

  const initialValues = {
    selectedProvider: "",
    selectedPurchaseOrder: "",
    selectedRAType: "",
    receiptAcknowledgement: {
      companyId,
      purchaseOrderId: "",
      providerId: "",
      raTypeId: "",
      raType: null,
      invoiceNo: "",
      invoiceDate: "",
      paymentDate: "",
      beneficiary: "",
      subtotal: 0,
      taxes: 0,
      importe: 0,
      porcentajeDescuento: 0,
      tasaIVA: 16,
      month: new Date().getMonth() + 1,
    },
    items: [
      {
        expenseAccountId: "",
        providerAccountId: "",
        amount: null,
        taxRate: 0,
        percentage: 100,
      },
    ],
  };

  const [formData, setFormData] = useState(initialValues);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getReceiptAcknowledgementDetail(id);
      const providerData = await getProviderDetail(
        res.data.providerId,
        res.data.companyId
      );
      const payload = {
        receiptAcknowledgement: res.data,
        items: res.data.items,
        selectedProvider: providerData.data,
      };

      setFormData(payload);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
    setLoading(false);
  };

  const createPayload = (values) => {
    let payload = { ...values };
    payload.receiptAcknowledgement.companyId = companyId;
    if (values.selectedRAType && values.selectedRAType.code !== "RA_NORMAL") {
      payload.items = values.items.map((item) => ({
        expenseAccountId: item.expenseAccountId,
        providerAccountId: values.receiptAcknowledgement.providerAccountId,
        amount: item.amount,
        taxRate: item.taxRate,
      }));

    }
    return payload;
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (formMode == "insert") {
        let payload = createPayload(values);
        const submitForm = await addReceiptAcknowledgement(payload);
        if (submitForm.status == 200) {
          openSnack("Contrarrecibo guardado exitosamente", "success");
        }
      } else if (formMode == "edit") {
        let payload = { ...values };
        const submitForm = await updateReceiptAcknowledgement(id, payload);
        if (submitForm.status == 200) {
          openSnack("Contrarrecibo actualizado exitosamente", "success");
        }
      }
      onSubmit();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    if (formMode == "edit") {
      fetchData();
    } else {
      setFormData(initialValues);
    }
  }, []);




  return (
    <Formik
      initialValues={{ ...formData }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue, touched, errors }) => {
        const isSpecialRA = values.selectedRAType && values.selectedRAType.code !== "RA_NORMAL";

        // Calculate subtotal and taxes based on RA type
        let calculatedSubtotal = 0;
        let calculatedTaxes = 0;

        if (isSpecialRA) {
          // For special RAs, calculations are handled in RASpecialItems component
          calculatedSubtotal = values.receiptAcknowledgement.subtotal;
          calculatedTaxes = values.receiptAcknowledgement.taxes;
        } else {
          // For normal RAs, calculate from items
          calculatedSubtotal = values.items.reduce(
            (sum, item) => sum + (parseFloat(item.amount) || 0),
            0
          );

          calculatedTaxes = values.items.reduce(
            (sum, item) =>
              sum + (parseFloat((item.amount * item.taxRate) / 100) || 0),
            0
          );

          // Update Formik's state if values change
          if (values.receiptAcknowledgement.subtotal !== calculatedSubtotal) {
            setFieldValue("receiptAcknowledgement.subtotal", calculatedSubtotal);
          }

          if (values.receiptAcknowledgement.taxes !== calculatedTaxes) {
            setFieldValue("receiptAcknowledgement.taxes", calculatedTaxes);
          }
        }

        return (
          <Form>
            <Stack
              direction={"column"}
              spacing={2}
              divider={<Divider orientation="horizontal" flexItem />}
            >
              <Stack direction={"column"}>
                <Typography variant="h4" mb={2}>
                  Información del contrarrecibo
                </Typography>
                <RAHeader
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  errors={errors}
                />
              </Stack>
              <Stack direction={"column"} spacing={2}>
                <Typography variant="h4">Detalle</Typography>
                {isSpecialRA ? (
                  <RASpecialItems />
                ) : (
                  <RAItems />
                )}
              </Stack>
              <Stack direction={"column"} spacing={2}>
                <Typography variant="h4">Resumen</Typography>
                <RASummary
                  subtotal={values.receiptAcknowledgement.subtotal}
                  taxes={values.receiptAcknowledgement.taxes}
                />
              </Stack>
            </Stack>
            <Button sx={{ mt: 2 }} type="submit" variant="contained">
              {formMode === "edit" ? "Actualizar" : "Añadir"}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReceiptAcknowledgementForm;
