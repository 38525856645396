import React from "react";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { Field } from "formik";
import CurrencyFieldText from "../../atoms/Inputs/CurrencyField";
import PropTypes from "prop-types";

const TariffFractionForm = ({ values, handleChange, handleBlur, touched, errors }) => {
  return (
    <Grid container spacing={2}>

      <Grid item xs={4}>
        <FormControlLabel
          control={
            <Field
              name="export.hasLock"
              as={Checkbox}
              type="checkbox"
            />
          }
          label="¿Tiene candado?"
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          fullWidth
          name="export.requestNo"
          label="Número de Pedimento"
          value={values.export.requestNo}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched?.export?.requestNo && Boolean(errors?.export?.requestNo)}
          helperText={touched?.export?.requestNo && errors?.export?.requestNo}
        />
      </Grid>

      {values.export.hasLock && (
        <Grid item xs={4}>
          <Field
            name="export.lockNo"
            as={TextField}
            label="Número de candado"
            fullWidth
            error={touched?.export?.lockNo && Boolean(errors?.export?.lockNo)}
            helperText={touched?.export?.lockNo && errors?.export?.lockNo}
          />
        </Grid>
      )}
    </Grid>
  );
};

TariffFractionForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default TariffFractionForm;
