import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Typography, Box, Divider } from "@mui/material";
import Changelog from "../../components/Changelog";

export default function HomeScreen() {
  return (
    <DashboardLayout>
      <Box sx={{ p: 2 }}>
        <Typography variant="h2">Bienvenido al ERP</Typography>
        <Typography variant="body2">Versión 0.1.12</Typography>
        <img src="https://static.wixstatic.com/media/459682_215892e098fd482e9112e38f62553803~mv2_d_1843_1229_s_2.jpg/v1/fill/w_1240,h_967,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/459682_215892e098fd482e9112e38f62553803~mv2_d_1843_1229_s_2.jpg" alt="Logo" />
        <Divider />
        <Changelog />
      </Box>
    </DashboardLayout>
  );
}
