import React, { useState } from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";
import {
  deleteReceiptAcknowledgement,
  getReceiptAcknowledgements,
  exportReceiptAcknowledgementTxt,
  programPayments,
  exportRA,
  cancelReceiptAcknowledgements,
} from "../../api/reciptAcknowledgement";
import LGModalCrud from "../LGCrud/LGModalCrud";
import ReceiptAcknowledgementForm from "./REceiptAckowledgementForm";
import { currencyFormatterMXN } from "../../services/valueFormatters";
import LGModal from "../../molecules/LGModal";
import RAExportModal from "../../molecules/ReceiptAcknowledgements/RAExportModal";
import { useSnack } from "../../services/SnakbarProvider";
import dayjs from "dayjs";
import RAFileUpload from "../../molecules/ReceiptAcknowledgements/RAFileUpload";
import RAFilesTable from "../RA/RAFilesTable";

export default function ReceiptAcknowledgementCrud({ props }) {
  const { companyId } = props;
  const { openSnack } = useSnack();
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const handleExport = async (params) => {
    console.log(params);
    try {
      const response = await exportReceiptAcknowledgementTxt(params);
      const fileName = `contrarrecibos_${dayjs().format('YYYYMMDD_HHmmss')}.txt`;
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      
      setExportModalOpen(false);
      openSnack("Archivo exportado exitosamente", "success");
    } catch (error) {
      openSnack("Error al exportar el archivo", "error");
    }
  };

  const handleFileDownload = async (id) => {
    try {
      
      const response = await exportRA(id);
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "contrarrecibo";

      if (
        contentDisposition &&
        contentDisposition.indexOf("attachment") !== -1
      ) {
        // Extract filename from Content-Disposition header
        const matches = contentDisposition.match(/filename="([^"]*)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      // Get the file extension from the filename if not available in content-disposition
      const fileExtension = fileName.split(".").pop();
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      // Set the download attribute with the filename including extension
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      openSnack(
        "No se pudo generar el PDF",
        "error"
      );
    }
  };

  const handleSchedule = async (selectedRows) => {
    if (selectedRows.length === 0) {
      openSnack("No se seleccionaron contrarrecibos", "warning");
      return;
    }
    try {
      await programPayments(selectedRows);
      openSnack("Contrarrecibos programados exitosamente", "success");
    } catch (error) {
      console.log(error);
      openSnack("Error al programar los contrarrecibos", "error");
    }
  };

  const handleCancel = async (selectedRows) => {
    if (selectedRows.length === 0) {
      openSnack("No se seleccionaron contrarrecibos", "warning");
      return;
    }
    try {
      await cancelReceiptAcknowledgements(selectedRows);
      openSnack("Contrarrecibos cancelados exitosamente", "success");
    } catch (error) {
      console.log(error);
      openSnack("Error al cancelar los contrarrecibos", "error");
    }
  };

  const setStatusColor = (statusCode) => {
    switch (statusCode) {
      case "RA_POR_PAGAR":
        return "info";
      case "RA_EN_REVISION":
        return "warning";
      case "RA_PAGADO":
        return "success";
      case "RA_CANCELLED":
        return "error";
    }
  };

  return (
    <>
      <LGModal
        open={exportModalOpen}
        handleClose={() => setExportModalOpen(false)}
        header="Exportar Contrarrecibos"
      >
        <RAExportModal companyId={companyId} onSubmit={handleExport} />
      </LGModal>

      <Box width={"100%"}>
        <LGModalCrud
          props={{
            actions: ["create", "update", "delete", "detail", "select", "download"],
            data: { companyId },
            fetchFunction: getReceiptAcknowledgements,
            deleteFunction: deleteReceiptAcknowledgement,
            downloadFunction: handleFileDownload,
            detailMenu: [
              {
                label: "Documentos",
                handler: () => {},
                header: "Documentos", 
                body: <RAFilesTable/>,
              },
            ],
            customButtons: [
              {
                label: "Exportar",
                icon: "download",
                color: "primary",
                onClick: () => setExportModalOpen(true),
              },
              {
                label: "Programar",
                icon: "schedule",
                color: "secondary",
                onClick: (selectedRows) => handleSchedule(selectedRows),
                showOnlyWithSelection: true,
              },
              {
                label: "Cancelar",
                icon: "cancel",
                color: "error",
                onClick: (selectedRows) => handleCancel(selectedRows),
                showOnlyWithSelection: true,
              },
            ],
            columns: [
              {
                field: "code",
                headerName: "Clave",
                flex: 0.2,
              },
              {
                field: "provider.code",
                headerName: "Proveedor",
                valueGetter: (value, row) => row.provider ? `${row.provider.code}` : "",
                flex: 0.3,
              },
              {
                field: "invoiceNo",
                headerName: "No. Factura",
                flex: 0.3,
              },
              {
                field: "total",
                headerName: "Total",
                flex: 0.2,
                ...currencyFormatterMXN,
              },
              {
                field: "status",
                headerName: "Estatus",
                flex: 0.2,
                renderCell: (params) => (
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Chip
                      size="small"
                      label={params.row.status.name.toUpperCase()}
                      color={setStatusColor(params.row.status.code)}
                    />
                  </Box>
                ),
              },
              //   {
              //     field: "accounts",
              //     headerName: "Cuentas contables",
              //     flex: 0.5,
              //     renderCell: (params) => (
              //       <Stack direction={"column"}>
              //         {params.value.map((e) => (
              //           <Typography variant="body2" key={e.id}>
              //             {e.contpaqiAccountNo} - {e.description}
              //           </Typography>
              //         ))}
              //       </Stack>
              //     ),
              //   },
            ],
            addModalData: {
              header: "Agregar Contrarrecibo",
              body: <ReceiptAcknowledgementForm companyId={companyId} />,
            },
            editModalData: {
              header: "Editar Contrarrecibo",
              body: <ReceiptAcknowledgementForm companyId={companyId} />,
            },
          }}
        />
      </Box>
    </>
  );
}
