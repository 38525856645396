import React from "react";
import { Box } from "@mui/material";
import LGModalCrud from "../LGCrud/LGModalCrud";
import ExportFileUpload from "./ExportFileUpload";
import { deleteExportFile, downloadExportFile, getExportFileList, uploadExportFiles } from "../../api/exports";
import { useSnack } from "../../services/SnakbarProvider";
import GenericFileUpload from "../Common/GenericFileUpload";

export default function ExportFilesTable({ props }) {
  const { id } = props;
  const { openSnack } = useSnack();

  const fileOptions = [
    "Factura comercial",
    "Lista de empaque",
    "Certificado de origen",
    "Documento de transporte",
    "Pedimento",
    "Factura Fiscal"
  ];

  const handleFileDownload = async (fileId) => {
    try {
      const response = await downloadExportFile(fileId);
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "export_document";

      if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
        const matches = contentDisposition.match(/filename="([^"]*)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      openSnack("Error al descargar el archivo", "error");
    }
  };

  return (
    <Box width={"100%"}>
      {id && (
        <LGModalCrud
          props={{
            actions: ["create", "download", "delete"],
            data: { exportId: id },
            fetchFunction: getExportFileList,
            deleteFunction: deleteExportFile,
            downloadFunction: handleFileDownload,
            columns: [
              {
                field: "fileConcept",
                headerName: "Nombre del archivo",
                flex: 0.5,
              },
            ],
            addModalData: {
              header: "Agregar Documentos",
              body: <GenericFileUpload 
                fileNameOptions={fileOptions}
                uploadFunction={uploadExportFiles}
                id={id}
              />,
            },
          }}
        />
      )}
    </Box>
  );
} 