import React from 'react';
import { FieldArray, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid, Button, IconButton, Stack, Icon, Divider } from '@mui/material';
import { useSnack } from '../../services/SnakbarProvider';
import { handleError } from '../../services/errorHandler';
import RAInputFile from '../../atoms/ReceiptAcknowledgements/RAInputFile';
import { uploadRAFiles } from '../../api/reciptAcknowledgement';

const validationSchema = Yup.object().shape({
  files: Yup.array().of(
    Yup.object().shape({
      fileConcept: Yup.string().required('Seleccionar concepto'),
      file: Yup.mixed().required('Seleccionar archivo'),
    })
  ),
});

const RAFileUpload = ({props}) => {
    const { id, onSubmit } = props;
  const { openSnack } = useSnack();
console.log(id);

  const handleSubmit = async (values) => {
    for (const e of values.files) {
      const payload = { fileConcept: e.fileConcept, id, fileCode: e.fileCode, receiptId: id };
      try {
        await uploadRAFiles(payload, e.file);
        openSnack(`${e.fileConcept} subido exitosamente`, 'success');
      } catch (e) {
        openSnack(handleError(e), 'error');
      }
    }
    onSubmit();
  };

  return (
    <Formik
      initialValues={{ files: [{ fileConcept: '', fileCode: '', file: null }] }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <Grid container spacing={2}>
            <FieldArray name="files">
              {({ push, remove }) => (
                <Grid container spacing={2} item xs={12}>
                  {values.files.map((file, index) => (
                    <Grid item xs={12} key={index}>
                      <Stack direction="row">
                        <RAInputFile name={`files[${index}]`} />
                        <IconButton
                          onClick={() => remove(index)}
                          disabled={values.files.length === 1}
                          color="error"
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </Stack>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => push({ fileConcept: '', fileCode: '', file: null })}
                      color="success"
                    >
                      <Icon>add</Icon>
                    </Button>
                  </Grid>
                </Grid>
              )}
            </FieldArray>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" fullWidth>
                Upload Files
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default RAFileUpload; 