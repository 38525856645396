import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { uploadExportFiles } from "../../api/exports";
import { useSnack } from "../../services/SnakbarProvider";

const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/xml",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "image/png",
  "image/jpeg",
];

const fileConcepts = [
  "Factura comercial",
  "Lista de empaque",
  "Certificado de origen",
  "Documentos de transporte",
  "Documentos aduaneros"
];

const validationSchema = Yup.object().shape({
  files: Yup.array().of(
    Yup.mixed()
      .required("Archivo requerido")
      .test(
        "fileFormat",
        "Formato no soportado",
        value => value && SUPPORTED_FORMATS.includes(value.type)
      )
  )
});

export default function ExportFileUpload({ exportId }) {
  const { openSnack } = useSnack();
  
  const formik = useFormik({
    initialValues: {
      files: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      const payload = {
        metadata: fileConcepts.map(concept => ({
          fileConcept: concept,
          exportId
        }))
      };
      
      try {
        await uploadExportFiles(JSON.stringify(payload.metadata), values.files);
        openSnack("Archivos subidos exitosamente", "success");
      } catch (error) {
        openSnack("Error al subir los archivos", "error");
      }
    }
  });

  return (
    <Box sx={{ margin: "0 auto", padding: "2rem" }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {fileConcepts.map((concept, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <Typography variant="body1">{concept}</Typography>
              </Grid>
              <Grid item xs={6}>
                <input
                  id={`files.${index}`}
                  name={`files.${index}`}
                  type="file"
                  onChange={(event) => {
                    formik.setFieldValue(
                      `files.${index}`,
                      event.currentTarget.files[0]
                    );
                  }}
                  onBlur={formik.handleBlur}
                  accept=".pdf,.xml,.docx,.xlsx,.png,.jpg"
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Subir Archivos
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
} 