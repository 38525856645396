import React from 'react';
import { useField } from 'formik';
import { Stack, TextField, Button, Typography, MenuItem } from '@mui/material';

const RAInputFile = ({ name }) => {
  const [field, meta, helpers] = useField(name);
  const [conceptField, conceptMeta] = useField(`${name}.fileConcept`);
  const [fileField, fileMeta] = useField(`${name}.file`);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    helpers.setValue({
      ...field.value,
      file: file,
      fileCode: file?.name || ''
    });
  };

  return (
    <Stack direction="row" spacing={2} sx={{ flex: 1 }}>
      <TextField
        select
        fullWidth
        label="Concepto"
        {...conceptField}
        error={conceptMeta.touched && Boolean(conceptMeta.error)}
        helperText={conceptMeta.touched && conceptMeta.error}
      >
        <MenuItem value="Factura">Factura</MenuItem>
        <MenuItem value="Nota de recepción">Nota de recepción</MenuItem>
      </TextField>
      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id={`file-input-${name}`}
      />
      <label htmlFor={`file-input-${name}`}>
        <Button
          variant="contained"
          component="span"
          sx={{ height: '56px', minWidth: '150px' }}
        >
          Seleccionar archivo
        </Button>
      </label>
      {fileField.value && (
        <Typography
          variant="body2"
          sx={{
            alignSelf: 'center',
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {fileField.value.name}
        </Typography>
      )}
    </Stack>
  );
};

export default RAInputFile; 