import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
  Divider,
} from "@mui/material";
import { FastField, Field, useFormikContext } from "formik";
import { getProviderAccountsList } from "../../api/providers";
import { getExpenseAccountList } from "../../api/accounting";
import LGItemCellHeader from "../../atoms/Common/LGItemCellHeader";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import { LGNumber } from "../../atoms/Inputs/LGNumber.tsx";

const RASpecialItems = () => {
  const { values, setFieldValue } = useFormikContext();
  const { raTypeId, companyId } = values.receiptAcknowledgement;
  const importe = values.receiptAcknowledgement.importe || 0;
  const porcentajeDescuento =
    values.receiptAcknowledgement.porcentajeDescuento || 0;
  const tasaIVA = values.receiptAcknowledgement.tasaIVA || 0;

  const getDefaultValues = () => {
    if (companyId === 2) {
      if (values.receiptAcknowledgement.raType?.code === "RA_LEASE") {
        return [
          { expenseAccountCode: "5102-1300-0001", percentage: 97.5 },
          { expenseAccountCode: "5201-1300-0001", percentage: 1.25 },
          { expenseAccountCode: "5202-1300-0001", percentage: 1.25 },
        ];
      } else if (values.receiptAcknowledgement.raType?.code === "RA_LIGHT") {
        return [
          { expenseAccountCode: "5102-2000-0001", percentage: 97.5 },
          { expenseAccountCode: "5201-2000-0001", percentage: 1.25 },
          { expenseAccountCode: "5202-2000-0001", percentage: 1.25 },
        ];
      } else if (values.receiptAcknowledgement.raType?.code === "RA_PHONE") {
        return [
          { expenseAccountCode: "5201-1700-0001", percentage: 20 },
          { expenseAccountCode: "5202-1700-0001", percentage: 80 },
        ];
      }
    }
    return [];
  };

  useEffect(() => {
    const defaultValues = getDefaultValues();
    if (defaultValues.length > 0) {
      // Set default values for porcentajeDescuento and tasaIVA
      setFieldValue("receiptAcknowledgement.porcentajeDescuento", 0);
      setFieldValue("receiptAcknowledgement.tasaIVA", 16);

      // Update items with default values
      const newItems = defaultValues.map(async (item) => {
        const expenseAccount = await getExpenseAccountByCode(
          item.expenseAccountCode
        );
        console.log(expenseAccount);
        return {
          expenseAccountId: expenseAccount?.id || "",
          percentage: item.percentage,
          amount:
            (values.receiptAcknowledgement.importe * item.percentage) / 100,
          taxRate: 16,
        };
      });

      Promise.all(newItems).then((resolvedItems) => {
        setFieldValue("items", resolvedItems);
      });
    }
  }, [raTypeId, companyId]);

  const getExpenseAccountByCode = async (code) => {
    try {
      const response = await getExpenseAccountList({
        contpaqiCode: code,
        companyId: companyId,
      });
      return response.data[0];
    } catch (error) {
      console.error("Error fetching expense account:", error);
      return null;
    }
  };

  const calculateTotals = () => {
    const { importe, porcentajeDescuento, tasaIVA } =
      values.receiptAcknowledgement;
    let descuento =
      (values.receiptAcknowledgement.importe * porcentajeDescuento) / 100;
    let subtotal = values.receiptAcknowledgement.importe - descuento;
    let taxes = subtotal * tasaIVA / 100;
    let total = subtotal + taxes;
    setFieldValue("receiptAcknowledgement.subtotal", subtotal);
    setFieldValue("receiptAcknowledgement.taxes", taxes);
    setFieldValue("receiptAcknowledgement.total", total);
  };

  // Update item amounts when importe or percentages change
  useEffect(() => {
    if (
      values.items &&
      values.items.length > 0 &&
      values.receiptAcknowledgement.importe
    ) {
      const updatedItems = values.items.map((item) => ({
        ...item,
        amount: (values.receiptAcknowledgement.importe * item.percentage) / 100,
      }));
      setFieldValue("items", updatedItems);
    }
  }, [
    values.receiptAcknowledgement.importe,
    values.items.map((item) => item.percentage).join(","),
  ]);

  useEffect(() => {
    calculateTotals();
  }, [importe, porcentajeDescuento, tasaIVA]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Field
          label="Cuenta del proveedor"
          name={`receiptAcknowledgement.providerAccountId`}
          as={DropdownSelector}
          fetchFunction={getProviderAccountsList}
          fetchParams={{
            providerId: values.receiptAcknowledgement.providerId,
          }}
          optionLabel="contpaqiAccountNo"
        />
      </Grid>
      <Grid item xs={6}>
        <FastField
          name="receiptAcknowledgement.importe"
          as={TextField}
          fullWidth
          label="Importe"
          type="number"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <LGItemCellHeader label="Cuenta contable" />
          </Grid>
          <Grid item xs={3}>
            <LGItemCellHeader label="Porcentaje" />
          </Grid>
          <Grid item xs={3}>
            <LGItemCellHeader label="Subtotal" />
          </Grid>
        </Grid>
      </Grid>

      {values.items.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                name={`items.${index}.expenseAccountId`}
                as={DropdownSelector}
                fetchFunction={getExpenseAccountList}
                fetchParams={{
                  companyId: companyId,
                  pageSize: 1000,
                }}
                optionLabel={["name", "contpaqiCode"]}
                optionSeparator=" | "
              />
            </Grid>
            <Grid item xs={3}>
              <FastField
                name={`items.${index}.percentage`}
                as={LGNumber}
                fullWidth
                label="Porcentaje"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography>${item.amount?.toFixed(2) || 0}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Porcentaje Descuento"
              as={LGNumber}
              value={porcentajeDescuento}
              onChange={(e) =>
                setFieldValue(
                  "receiptAcknowledgement.porcentajeDescuento",
                  parseFloat(e.target.value) || 0
                )
              }
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Tasa IVA"
              as={LGNumber}
              value={tasaIVA}
              onChange={(e) =>
                setFieldValue(
                  "receiptAcknowledgement.tasaIVA",
                  parseFloat(e.target.value) || 0
                )
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RASpecialItems;
