import React from "react";
import { Box } from "@mui/material";


import LGModalCrud from "../LGCrud/LGModalCrud";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import RAFileUpload from "../../molecules/ReceiptAcknowledgements/RAFileUpload"; 
import { deleteRAFile, downloadRAFile, getRAFileList } from "../../api/reciptAcknowledgement";

export default function RAFilesTable({ props }) {
  const { id } = props;
  const { openSnack } = useSnack();

  const handleFileDownload = async (id) => {
    try {
      const response = await downloadRAFile(id);
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "downloadedFile";

      if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
        const matches = contentDisposition.match(/filename="([^"]*)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      const fileExtension = fileName.split(".").pop();
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  return (
    <Box width={"100%"}>
      {id && (
        <LGModalCrud
          props={{
            actions: ["create", "download", "delete"],
            data: { raId: id },
            fetchFunction: getRAFileList,
            deleteFunction: deleteRAFile,
            downloadFunction: handleFileDownload,
            detailMenu: [
              {
                label: "Descargar",
                handler: handleFileDownload,
                header: "Descargar",
                body: <RAFileUpload />,
              },
            ],
            columns: [
              {
                field: "fileConcept",
                headerName: "Nombre del archivo",
                flex: 0.5,
              },
            ],
            addModalData: {
              header: "Agregar Documentos",
              body: (
                <RAFileUpload
                  props={{
                    id: id,
                    onSubmit: () => console.log("SUBMIT"),
                  }}
                />
              ),
            },
            editModalData: {
              header: "Editar Archivo",
              body: <p>Formulario editar archivo</p>,
            },
          }}
        />
      )}
    </Box>
  );
} 