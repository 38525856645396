import React from "react";
import { useFormikContext } from "formik";
import { TextField, Button, MenuItem, Grid } from "@mui/material";

const GenericInputFile = ({ name, fileNameOptions = [] }) => {
  // name_format = files[0]
  const { setFieldValue, getFieldProps, touched, errors, values } = useFormikContext();

  const typeFieldProps = getFieldProps(`${name}.fileConcept`);
  const codeFieldProps = getFieldProps(`${name}.fileCode`);
  const fileError = errors[name]?.file;
  const fileTouched = touched[name]?.file;
  const currentFile = getFieldProps(name).value?.file;

  const handleFileChange = (event) => {
    console.log(values);
    
    const file = event.currentTarget.files[0];
    console.log('File selected:', file);
    setFieldValue(`${name}.file`, file);
  };

  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item xs={4}>
        {fileNameOptions.length > 0 ? (
          <TextField
            select
            label="Selecionar..."
            {...typeFieldProps}
            error={Boolean(touched[name]?.type && errors[name]?.type)}
            helperText={touched[name]?.type && errors[name]?.type}
            fullWidth
          >
            {fileNameOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            label="Nombre del archivo"
            {...typeFieldProps}
            error={Boolean(touched[name]?.type && errors[name]?.type)}
            helperText={touched[name]?.type && errors[name]?.type}
            fullWidth
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <TextField {...codeFieldProps} label="Código" />
      </Grid>
      <Grid item xs={4}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Button variant="contained" component="label" fullWidth>
              Subir Archivo
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
          </Grid>
          {currentFile && (
            <Grid item>
              <div style={{ 
                fontSize: '0.6rem', 
                color: '#666', 
                wordBreak: 'break-all',
                padding: '4px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                maxWidth: '100%'
              }}>
                {currentFile.name}
              </div>
            </Grid>
          )}
          {fileTouched && fileError && (
            <Grid item>
              <div style={{ color: "red", fontSize: "0.8em" }}>{fileError}</div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GenericInputFile; 