import React, { useState } from "react";
import { Grid, Button, Divider, Box, Stack, Icon, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PODetailHeader from "../../molecules/PurchaseOrder/PODetailHeader";
import PODetailTable from "../../organisms/PurchaseOrders/PODetailTable";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import { approvePurchaseOrder, exportPO, updatePurchaseOrder } from "../../api/purchaseOrders";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import { useCompany } from "../../contexts/CompanyContext";
import PODetailTableMaterial from "../../organisms/PurchaseOrders/PODetailTableMaterial";

const PODetailScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { openSnack } = useSnack();
  const POdata = location.state || {};
  const id = POdata.id;
  const { selectedCompany } = useCompany();
  const [isSaved, setIsSaved] = useState(false);

  const initialValues = {
    purchaseOrder: {
      ...POdata,
    },
    items:
      Array.isArray(POdata.items) && POdata.items.length > 0
        ? POdata.items
        : new Array(Number(POdata.noConcepts) || 1).fill({
            quantity: "",
            price: "",
            productId: null,
            unitId: "",
            concept: "",
            workOrderId: "",
            assetTypeId: "",
            assetId: "",
            taxRate: 16,
            subtotal: 0,
          }),
  };

  const getActivitiyIds = () => {
    const providerData = POdata.provider;
    const activityList = providerData?.activities;
    return activityList?.map((e) => e.expenseAccountId);
  };

  const handleFileDownload = async (showPrices) => {
    try {
      
      const response = await exportPO(id, showPrices);
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "ordencompra";

      if (
        contentDisposition &&
        contentDisposition.indexOf("attachment") !== -1
      ) {
        // Extract filename from Content-Disposition header
        const matches = contentDisposition.match(/filename="([^"]*)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      // Get the file extension from the filename if not available in content-disposition
      const fileExtension = fileName.split(".").pop();
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      // Set the download attribute with the filename including extension
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      openSnack(
        "No se pudo generar el PDF, verifica que el proveedor tenga contacto y dirección",
        "error"
      );
    }
  };

  const handleApprove = async () => {
    try {
      const res = await approvePurchaseOrder(id);
      openSnack("Órden de compra aprobada exitosamente", "success");
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const validationSchema = Yup.object().shape({});

  const handleSubmit = async (values) => {

    let payload = values;
    payload.items = payload.items.map((e) => ({
      ...e,
      productId: e.productId && e.productId != 0 ? e.productId : null,
    }));

    delete payload.purchaseOrder.id;
    try {
      const res = await updatePurchaseOrder(id, payload);
      openSnack("Órden de compra guardada exitosamente", "success");
      setIsSaved(true);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  if (!selectedCompany) {
    return (
      <DashboardLayout>
        <Box p={4}>
          <Typography>Seleccione una compañía primero</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    
    <DashboardLayout>
      <Box p={2}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid 
                  item 
                  xs={12} 
                  sx={{
                    position: 'sticky',
                    top: 50,
                    backgroundColor: 'background.default',
                    zIndex: 1000,
                    paddingTop: 2,
                    paddingBottom: 2,
                  }}
                >
                  <PODetailHeader data={values.purchaseOrder} />
                  <Divider sx={{ marginTop: 2 }} />
                </Grid>
                <Grid item xs={12}>
                  {(values.purchaseOrder?.purchaseOrderType?.code !== "POTYPE_MATERIAL") ? (
                    <Box>
                      <Typography variant="h6" color="primary" gutterBottom>
                        {values.purchaseOrder?.purchaseOrderType?.code}
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => navigate("/purchase-orders")}
                        sx={{ mb: 2 }}
                      >
                        Volver
                      </Button>
                    </Box>
                  ) : (
                  <PODetailTableMaterial
                    orderType={values.purchaseOrder.purchaseOrderType}
                    companyId={selectedCompany.id}
                    items={values.items}
                    noConcepts={values.noConcepts}
                    activityIds={getActivitiyIds()}
                  />
                  )}
                </Grid>
                <Grid item xs={12} spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <Button type="submit" variant="contained" color="primary">
                      Guardar
                    </Button>
                    <Button variant="contained" disabled={!isSaved} onClick={handleApprove} color="success">
                      Aprobar
                    </Button>
                    <Button variant="contained" color="primary" disabled={!isSaved} onClick={() => handleFileDownload(true)}>
                    <Icon>monetization_on</Icon>  Descargar PDF con precios 
                    </Button>
                    <Button variant="contained" color="primary" disabled={!isSaved} onClick={() => handleFileDownload(false)}>
                      <Icon>download</Icon> Descargar PDF sin precios
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </DashboardLayout>
  );
};

export default React.memo(PODetailScreen);
