import { api } from "..";

const endpoint = "/api/providers";

// PROVIDERS
export const getProviderList = (queryParams) => {
  return api.get(`${endpoint}`, { params: queryParams });
};

export const addProvider = (data) => {
  return api.post(`${endpoint}`, data);
};

export const updateProvider = (id, data) => {
  return api.put(`${endpoint}/${id}`, data);
};

export const patchProvider = (id, data) => {
  return api.patch(`${endpoint}/${id}`, data);
};

export const deleteProvider = (id) => {
  return api.delete(`${endpoint}/${id}`);
};

export const getProviderDetail = (id, companyId) => {
  return api.get(`${endpoint}/${id}/${companyId}`);
};

export const getProviderByTaxId = (taxId) => {
  return api.get(`${endpoint}/tax-id/${taxId}`);
};

// PROVIDER TYPES
export const getProviderTypes = () => {
  return api.get(`${endpoint}/type`);
};

export const addProviderFromAccount = (data) => {
  return api.post(`${endpoint}/from-account`, data);
};

export const updateProviderFromAccount = (id, data) => {
  return api.put(`${endpoint}/from-account/${id}`, data);
};

// ACCOUNTS
export const getProviderAccountsList = (queryParams) => {
  return api.get(`${endpoint}/accounts`, { params: queryParams });
};

export const getProviderAccountDetail = (id) => {
  return api.get(`${endpoint}/accounts/${id}`);
};

export const addProviderAccounts = (data) => {
  return api.post(`${endpoint}/accounts`, data);
};

export const updateProviderAccounts = (id, data) => {
  return api.put(`${endpoint}/accounts/${id}`, data);
};

export const deleteProviderAccounts = (id) => {
  return api.delete(`${endpoint}/accounts/${id}`);
};

// ADDRESSES
export const getProviderAddressesList = (providerId) => {
  return api.get(`${endpoint}/address/${providerId}`);
};

export const addProviderAddress = (data) => {
  return api.post(`${endpoint}/address/`, data);
};

export const deleteProviderAddresses = (providerId) => {
  return api.delete(`${endpoint}/address/${providerId}`);
};

// CONTACTS
export const getProviderContactList = (providerId) => {
  return api.get(`${endpoint}/contacts/${providerId}`);
};

export const addProviderContact = (data) => {
  return api.post(`${endpoint}/contacts`, data);
};

export const updateProviderContact = (id, data) => {
  return api.put(`${endpoint}/contacts/${id}`, data);
};

export const deleteProviderContact = (id) => {
  return api.delete(`${endpoint}/contacts/${id}`);
};

// BANK DETAILS
export const getProviderBankDetailList = (providerId) => {
  return api.get(`${endpoint}/${providerId}/bank-details`);
};

export const getProviderBankDetails = (bankId) => {
  return api.get(`${endpoint}/bank-details/${bankId}`);
}

export const addProviderBankDetail = (data, providerId) => {
  return api.post(`${endpoint}/bank-details/${providerId}`, data);
};

export const updateProviderBankDetail = (bankId, data) => {
  return api.put(`${endpoint}/bank-details/${bankId}`, data);
};

export const deleteProviderBankDetail = (id) => {
  return api.delete(`${endpoint}/bank-details/${id}`);
};

// ACTIVITY
export const getProviderActivityList = (queryParams) => {
  return api.get(`${endpoint}/activities/`, { params: queryParams });
};

export const addProviderActivity = (data) => {
  return api.post(`${endpoint}/activities/`, data);
};

export const updateProviderActivity = (id, data) => {
  return api.put(`${endpoint}/activities/${id}`, data);
};

export const deleteProviderActivity = (id) => {
  return api.delete(`${endpoint}/activities/${id}`);
};

// CONTRACTS
export const getProviderContractsList = (queryParams) => {
  return api.get(`${endpoint}/contracts/`, {
    params: queryParams,
  });
};

export const uploadProviderContract = (data, file) => {
  const formData = new FormData();
  formData.append("providerContract", data);
  formData.append("file", file);

  return api.post(`${endpoint}/contracts/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const downloadProviderContractFile = (contractId) => {
  return api.get(`${endpoint}/contracts/contract/${contractId}`, {
    responseType: "blob",
    headers: { Accept: "*/*" },
  });
};
