import React from "react";
import { FieldArray, Formik, Form } from "formik";
import * as Yup from "yup";
import { Grid, Button, IconButton, Stack, Icon, Divider } from "@mui/material";
import GenericInputFile from "../../atoms/Common/GenericInputFile";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";

const validationSchema = Yup.object().shape({
  files: Yup.array().of(
    Yup.object().shape({
      fileConcept: Yup.string().required("Seleccionar concepto"),
      file: Yup.mixed().required("Seleccionar archivo"),
    })
  ),
});

const GenericFileUpload = ({ 
  fileNameOptions = [], 
  uploadFunction, 
  id, 
  onSubmit 
}) => {
  const { openSnack } = useSnack();

  const handleSubmit = async (values) => {
    for (const e of values.files) {
      const payload = { fileConcept: e.fileConcept, id, fileCode: e.fileCode };
      try {
        await uploadFunction(payload, e.file);
        openSnack(`${e.fileConcept} subido exitosamente`, "success");
      } catch (e) {
        openSnack(handleError(e), "error");
      }
    }
    onSubmit();
  };

  return (
    <Formik
      initialValues={{ files: [{ fileConcept: "", fileCode: "", file: null }] }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <Grid container spacing={2} mr={2}>
            <Grid item xs={12}>
              <FieldArray name="files">
                {({ push, remove }) => (
                  <Grid container spacing={2} item xs={12} alignItems={"center"}>
                    {values.files.map((file, index) => (
                      <Grid item xs={12} key={index}>
                        <Stack direction={"row"}>
                          <GenericInputFile 
                            name={`files[${index}]`} 
                            fileNameOptions={fileNameOptions}
                          />
                          <IconButton
                            onClick={() => remove(index)}
                            disabled={values.files.length === 1}
                            color="error"
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </Stack>
                      </Grid>
                    ))}
                    <Grid item xs={12} alignItems={"center"}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => push({ fileConcept: "", fileCode: "", file: null })}
                        color="success"
                      >
                        <Icon>add</Icon>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </FieldArray>
            </Grid>
            <Divider />
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  const missingFiles = values.files.some(file => !file.file);
                  if (missingFiles) {
                    openSnack("No se han subido todos los archivos", "warning");
                  }
                }}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default GenericFileUpload; 