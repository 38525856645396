import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { FastField, Field, useFormikContext } from "formik";
import { TextField, MenuItem } from "@mui/material";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import { getCatalogue } from "../../api/common/catalogues";
import { LGNumber } from "../../atoms/Inputs/LGNumber.tsx";

export default function ProductPaperForm() {
  const { values, setFieldValue } = useFormikContext();
  const [productProps, setProductProps] = useState([]);

  const fetchProductProps = async () => {
    const response = await getCatalogue({ catalogueName: "PRODUCT_PROP" });
    setProductProps(response.data);
  };


  // Update props.2.value whenever thickness or thickness_unit changes
  const handleThicknessChange = () => {
    const concatenatedValue = `${values.thickness || ""}${values.thickness_unit?.toUpperCase() || ""}`;
    setFieldValue("props.2.value", concatenatedValue);
  };

  useEffect(() => {
    fetchProductProps();
    // Map props array to use IDs from productProps where codes match
    const props = [
      { key: "L" },
      { key: "A" },
      { key: "E" },
      { key: "F" },
      { key: "C" },
    ];
    const propsWithIds = props.map(prop => {
      const matchingProp = productProps.find(p => p.code === prop.key);
      return { key: matchingProp?.id || "" };
    });
    setFieldValue("props", propsWithIds);
    // Initialize props array

    setFieldValue("props", props);
  }, [setFieldValue]);

  useEffect(() => {
    handleThicknessChange();
  }, [values.thickness, values.thickness_unit]); // Trigger update when either value changes

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Field
          fullWidth
          name="props.0.value"
          label="Ancho (cm)"
          as={LGNumber}
          inputProps={{ step: "0.1" }}
        />
      </Grid>

      <Grid item xs={6}>
        <Field
          fullWidth
          name="props.1.value"
          label="Alto (cm)"
          as={LGNumber}
          inputProps={{ step: "0.1" }}
        />
      </Grid>

      <Grid item xs={6}>
        <Field
          fullWidth
          name="thickness"
          label="Grosor"
          as={LGNumber}
          inputProps={{ step: "0.1" }}
          onChange={(e) => {
            setFieldValue("thickness", e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <Field
          fullWidth
          name="thickness_unit"
          label="Unidad de Grosor"
          as={TextField}
          select
          onChange={(e) => {
            setFieldValue("thickness_unit", e.target.value);
          }}
        >
          <MenuItem value="g">g</MenuItem>
          <MenuItem value="pt">pt</MenuItem>
        </Field>
      </Grid>

      <Grid item xs={6}>
        <FastField
          name="props.3.value"
          label="Acabado"
          as={DropdownSelector}
          fetchFunction={getCatalogue}
          fetchParams={{ catalogueName: "PRODUCT_FINISH" }}
        />
      </Grid>

      <Grid item xs={6}>
        <FastField
          name="props.4.value"
          label="Color"
          as={DropdownSelector}
          fetchFunction={getCatalogue}
          fetchParams={{ catalogueName: "PRODUCT_COLOR" }}
        />
      </Grid>
    </Grid>
  );
}
