import { api } from "..";
const endpoint = "/api/exports";

export const getExportList = async (queryParams) => {
  return await api.get(`${endpoint}`, { params: queryParams });
};

export const createExport = async (data) => {
  return await api.post(`${endpoint}`, data);
};

export const updateExport = async (id, data) => {
  return await api.put(`${endpoint}/${id}`, data);
};

export const deleteExport = async (id) => {
  return await api.delete(`${endpoint}/${id}`);
};

export const getExportById = async (id) => {
  return await api.get(`${endpoint}/${id}`);
};


export const getExportFileList = async (params) => {
  return await api.get(`${endpoint}/files`, { params });
};

export const deleteExportFile = async (id) => {
  return await api.delete(`${endpoint}/files/${id}`);
};

export const downloadExportFile = async (id) => {
  return await api.get(`${endpoint}/files/${id}/download`, {
    responseType: 'blob'
  });
};

export const uploadExportFiles = (data, file) => {
  const metadata = { ...data, exportId: data.id };
  const formData = new FormData();
  formData.append("metadata", JSON.stringify(metadata));
  formData.append("file", file, file.name);

  return api.post(`${endpoint}/upload/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};