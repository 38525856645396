import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Icon,
  Stack,
  IconButton,
} from "@mui/material";
import { useSnack } from "../../../services/SnakbarProvider";
import { handleError } from "../../../services/errorHandler";
import NationalBankItem from "./NationalBankItem";
import ForeignBankItem from "./ForeignBankItem";
import LGModal from "../../../molecules/LGModal";
import ProviderBankForm from "../../../organisms/Providers/ProviderBanks/ProviderBankForm";
import ConfirmDialog from "../../../molecules/Common/ConfirmDialog";
import { deleteProviderBankDetail } from "../../../api/providers";

export default function BankAccountItem({ props, onChange }) {
  const {
    bankAccount,
    currency,
    bank,
    isForeign,
    foreignBank,
    id: bankId,
  } = props;
  console.log(`bankId: ${bankId}`);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { openSnack } = useSnack();

  const handleModalClose = () => {
    setOpenModal(false);
    onChange();
  };

  const handleCloseDialog = () => {
    setOpenConfirm(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteProviderBankDetail(bankId);
      openSnack("Cuenta bancaria eliminada correctamente", "success");
      handleCloseDialog();
      onChange();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  return (
    <>
      <LGModal
        open={openModal}
        header="Editar Cuenta bancaria"
        handleClose={handleModalClose}
      >
        <ProviderBankForm bankId={bankId} onSubmit={handleModalClose} />
      </LGModal>

      <ConfirmDialog
        open={openConfirm}
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmDelete}
      />

      <Box mt={4}>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <Typography variant="h6">{bank?.name || foreignBank}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Stack direction="row">
              <IconButton color="primary" onClick={() => setOpenModal(true)}>
                <Icon>edit</Icon>
              </IconButton>
              <IconButton color="error" onClick={() => setOpenConfirm(true)}>
                <Icon>delete</Icon>
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Box m={1} />
        <Grid container>
          {isForeign && (
            <Grid item xs={12}>
              <Typography variant="button" color="success.main">
                CUENTA EXTRANJERA
              </Typography>
            </Grid>
          )}
          <Grid container item xs={12}>
            <Stack
              alignItems="center"
              direction="row"
              gap={2}
              justifyContent="space-around"
            >
              <Icon>account_balance</Icon>
              <Typography variant="button" color="primary.main">
                Cuenta
              </Typography>
              <Typography>{bankAccount}</Typography>
            </Stack>
          </Grid>
          <Grid container item xs={12}>
            <Stack
              alignItems="center"
              direction="row"
              gap={2}
              justifyContent="space-around"
            >
              <Icon>currency_exchange</Icon>
              <Typography variant="button" color="primary.main">
                Moneda
              </Typography>
              <Typography variant="button" color="success.main">
                {currency?.symbol || "-"}
              </Typography>
              <Typography>{currency?.name}</Typography>
            </Stack>
          </Grid>

          {isForeign ? (
            <ForeignBankItem {...props} />
          ) : (
            <NationalBankItem {...props} />
          )}
        </Grid>
      </Box>
    </>
  );
}
